<template>
    <div class="item-card" :class="{'error': item.attributes.is_unavailable}">
        <div class="card-title-container">
            <p class="card-title">{{item.attributes.name}}</p>

            <div class="right">
                <p class="card-title">€{{item.attributes.value}}</p>
                <div class="box"></div>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].type">
            <div class="section-container">
                <p class="section-title">Type</p>
                <p class="section-info">{{item.attributes.options[0].type}}</p>
            </div>
        </div>

        <div class="multiple-section-container">
            <div class="section-container" v-if="item.attributes.options[0].width">
                <p class="section-title">Width</p>
                <p class="section-info">{{item.attributes.options[0].width}} cm</p>
            </div>
            <div class="section-container" v-if="item.attributes.options[0].drop">
                <p class="section-title">Drop</p>
                <p class="section-info">{{item.attributes.options[0].drop}} cm</p>
            </div>
            <div class="section-container" v-if="item.attributes.options[0].blind_type">
                <p class="section-title">Blind Type</p>
                <p class="section-info">{{item.attributes.options[0].blind_type}}</p>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].slat_qty || item.attributes.options[0].runner_qty">
            <div class="section-container" v-if="item.attributes.options[0].slat_qty">
                <p class="section-title">Slat Qty</p>
                <div class="row">
                    <p class="section-info">{{item.attributes.options[0].slat_qty}}</p>
                    <div class="box"></div>
                </div>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].runner_qty">
                <p class="section-title">Runner Qty</p>
                <div class="row">
                    <p class="section-info">{{item.attributes.options[0].runner_qty}}</p>
                    <div class="box"></div>
                </div>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].finish || item.attributes.options[0].finish_style">
            <div class="section-container" v-if="item.attributes.options[0].finish">
                <p class="section-title">Finish</p>
                <p class="section-info">{{item.attributes.options[0].finish}}</p>
            </div>
            <div class="section-container" v-if="item.attributes.options[0].finish_style">
                <p class="section-title">Finish Style</p>
                <p class="section-info">{{item.attributes.options[0].finish_style}}</p>
            </div>
        </div>


        <div class="multiple-section-container" v-if="item.attributes.options[0].recess_type || item.attributes.options[0].width_clearance">
            <div class="section-container" v-if="item.attributes.options[0].recess_type">
                <p class="section-title">Recess Type</p>
                <p class="section-info">{{item.attributes.options[0].recess_type}}</p>
            </div>
            <div class="section-container" v-if="item.attributes.options[0].width_clearance">
                <p class="section-title">{{$t('calculators.width_clearance')}}</p>
                <p class="section-info">{{item.attributes.options[0].width_clearance}}</p>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].chain_side || item.attributes.options[0].chain_type
        || item.attributes.options[0].controls_side || item.attributes.options[0].cord_side || item.attributes.options[0].wand_side">

            <div class="section-container" v-if="item.attributes.options[0].chain_side">
                <p class="section-title">Chain Side</p>
                <p class="section-info">{{item.attributes.options[0].chain_side}}</p>
            </div>
            <div class="section-container" v-if="item.attributes.options[0].chain_type">
                <p class="section-title">Chain Type</p>
                <p class="section-info">{{item.attributes.options[0].chain_type}}</p>
            </div>
            <div class="section-container" v-if="item.attributes.options[0].controls_side">
                <p class="section-title">Controls Side</p>
                <p class="section-info">{{item.attributes.options[0].controls_side}}</p>
            </div>
            <div class="section-container" v-if="item.attributes.options[0].cord_side">
                <p class="section-title">Cord Side</p>
                <p class="section-info">{{item.attributes.options[0].cord_side}}</p>
            </div>
            <div class="section-container" v-if="item.attributes.options[0].wand_side">
                <p class="section-title">Wand Side</p>
                <p class="section-info">{{item.attributes.options[0].wand_side}}</p>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].tile_cut_width || item.attributes.options[0].tile_cut_width_clearance">
            <div class="section-container" v-if="item.attributes.options[0].tile_cut_width">
                <p class="section-title">Tile Cut Width</p>
                <p class="section-info">{{item.attributes.options[0].tile_cut_width}} cm</p>
            </div>
            <div class="section-container" v-if="item.attributes.options[0].tile_cut_width_clearance">
                <p class="section-title">Tile Cut Width Clearance</p>
                <p class="section-info">{{item.attributes.options[0].tile_cut_width_clearance}}</p>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].tile_cut_height">
            <div class="section-container" v-if="item.attributes.options[0].tile_cut_height">
                <p class="section-title">Tile Cut Height</p>
                <p class="section-info">{{item.attributes.options[0].tile_cut_height}} cm</p>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].aluminium_cover || item.attributes.options[0].bottom_bar">
            <div class="section-container" v-if="item.attributes.options[0].aluminium_cover">
                <p class="section-title">Aluminium Cover</p>
                <p class="section-info">{{item.attributes.options[0].aluminium_cover}}</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].bottom_bar">
                <p class="section-title">Bottom Bar</p>
                <p class="section-info">{{item.attributes.options[0].bottom_bar}}</p>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].fabric_type || item.attributes.options[0].fabric_colour || item.attributes.options[0].fabric_colour_options">
            <div class="section-container" v-if="item.attributes.options[0].fabric_type">
                <p class="section-title">Fabric Type</p>
                <p class="section-info">{{item.attributes.options[0].fabric_type}}</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].fabric_colour">
                <p class="section-title">Fabric Colour</p>
                <p class="section-info">{{item.attributes.options[0].fabric_colour}}</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].fabric_colour_options">
                <p class="section-title">Fabric Colour Options</p>
                <p class="section-info">{{item.attributes.options[0].fabric_colour_options}}</p>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].fabric_colours_selected.length" v-for="colour in item.attributes.options[0].fabric_colours_selected">
            <div class="section-container">
                <p class="section-title">Fabric Colour</p>
                <p class="section-info">{{colour.name}}</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].fabric_colour_options == 'Two Colour' && colour.first_outer_slat_qty > 0">
                <p class="section-title">Outer Colour Slat Qty</p>
                <p class="section-info">{{colour.first_outer_slat_qty}}R / {{colour.first_outer_slat_qty}}L</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].fabric_colour_options == 'Two Colour' && colour.middle_slat_qty > 0">
                <p class="section-title">Middle Colour Slat Qty</p>
                <p class="section-info">{{colour.middle_slat_qty}}</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].fabric_colour_options == 'Three Colour' && colour.first_outer_slat_qty > 0">
                <p class="section-title">First Outer Colour Slat Qty</p>
                <p class="section-info">{{colour.first_outer_slat_qty}}R / {{colour.first_outer_slat_qty}}L</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].fabric_colour_options == 'Three Colour' && colour.second_outer_slat_qty > 0">
                <p class="section-title">Second Outer Colour Slat Qty</p>
                <p class="section-info">{{colour.second_outer_slat_qty}}R / {{colour.second_outer_slat_qty}}L</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].fabric_colour_options == 'Three Colour' && colour.middle_slat_qty > 0">
                <p class="section-title">Middle Colour Slat Qty</p>
                <p class="section-info">{{colour.middle_slat_qty}}</p>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].control_type || item.attributes.options[0].opening_type">
            <div class="section-container" v-if="item.attributes.options[0].control_type">
                <p class="section-title">Control Type</p>
                <p class="section-info">{{item.attributes.options[0].control_type}}</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].opening_type">
                <p class="section-title">Opening Type</p>
                <p class="section-info">{{item.attributes.options[0].opening_type}}</p>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].fabric_colour_zx|| item.attributes.options[0].fabric_colour_dt">
            <div class="section-container" v-if="item.attributes.options[0].fabric_colour_zx">
                <p class="section-title">Fabrics & Colours - ZX</p>
                <p class="section-info">{{item.attributes.options[0].fabric_colour_zx}}</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].fabric_colour_dt">
                <p class="section-title">Fabrics & Colours - DT</p>
                <p class="section-info">{{item.attributes.options[0].fabric_colour_dt}}</p>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].custom_colour_code">
            <div class="section-container">
                <p class="section-title">Custom Colour Code</p>
                <p class="section-info">{{item.attributes.options[0].custom_colour_code}}</p>
            </div>
        </div>

        <div class="multiple-section-container" v-if="item.attributes.options[0].motor || item.attributes.options[0].controller_type || item.attributes.options[0].controller_types">
            <div class="section-container" v-if="item.attributes.options[0].motor">
                <p class="section-title">Motor</p>
                <p class="section-info">{{item.attributes.options[0].motor}}</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].controller_type">
                <p class="section-title">Controller Type</p>
                <p class="section-info">{{item.attributes.options[0].controller_type}}</p>
            </div>

            <div class="section-container" v-if="item.attributes.options[0].controller_types">
                <p class="section-title">Controller Types</p>
                <p class="section-info">{{item.attributes.options[0].controller_types.map(b => b.attributes.name).join(', ')}}</p>
            </div>
        </div>

        <div v-if="item.attributes.note" class="divider"/>

        <div class="multiple-section-container" v-if="item.attributes.note">
            <div class="section-container note-field">
                <p class="section-title">Note</p>
                <p class="section-info">{{item.attributes.note}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import ConfirmModal from "@/components/modal/ConfirmModal";
import _ from "lodash";

export default {
    name: "ItemCardPrinting",
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            is_deleting: false
        }
    },
}
</script>

<style lang="scss" scoped>

.item-card {
    @apply w-full flex flex-col max-w-full border-1 border-black bg-grey-print rounded-md h-auto;

    &.error {
        @apply border-primary;
    }

    .card-title-container {
        @apply flex flex-row px-4 py-1 bg-black text-white justify-between;

        .card-title {
            @apply font-bold text-sm -mt-2;
        }

        .right {
            @apply flex flex-row;

            p {
                @apply my-auto -mt-2;
            }

            .box {
                @apply h-5 w-5 ml-4 bg-white my-auto rounded-sm;
            }
        }
    }

    .multiple-section-container {
        @apply flex flex-row px-4 pt-1 pb-4 border-b border-grey;

        &:last-of-type {
            @apply border-none;
        }

        .section-container {
            &:first-of-type {
                @apply mr-2;
                width: 40%;
            }

            &:last-of-type {
                width: 60%;
            }

            &.note-field {
                @apply w-full;
            }

            .section-title {
                @apply text-xs font-bold mb-1;
            }

            .section-info {
                @apply text-xs break-normal;
                word-break: break-word;
            }

            .row {
                @apply flex flex-row;

                .box {
                    @apply h-5 w-12 ml-2 bg-white border border-black rounded-sm;
                    margin-top: 0.4rem;
                }
            }
        }
    }
}
</style>