<template>
    <loading-screen v-if="is_loading_cart"></loading-screen>
    <div v-else class="page-container">
        <div class="page-content-container">
            <div class="header-container">
                <div class="left">
                    <router-link :to="{name: 'calculators-index'}">
                        <font-awesome-icon :icon="['far', 'home-lg-alt']"/>
                    </router-link>
                    <h1 >{{ $t('cart.cart') }}</h1>
                </div>

                <div class="right" v-if="cart">
                    <p class="price-container" v-if="cart.relationships.blinds.data.length">€{{cartTotal}}</p>
                    <button class="button --outline --primary --wide" :disabled="!cart.relationships.blinds.data.length" @click="toggleSaveQuoteModal" v-if="$store.getters.hasPermission('store quotes')">{{ $t('quotes.save_as_quote') }}</button>
                    <router-link tag="button" class="button --primary --wide" :disabled="!cart.relationships.blinds.data.length" :to="{name: 'checkout'}">{{ $t('cart.checkout') }}</router-link>
                </div>
            </div>

            <div v-if="!cart || (cart && !cart.relationships.blinds.data.length)" class="empty-cart-container">
                <p>{{$t('cart.empty_cart_text')}}</p>
                <router-link tag="button" class="button --primary --wide" :to="{name: 'calculators-index'}"><font-awesome-icon :icon="['far', 'home-lg-alt']"/> {{$t('nav.home')}}</router-link>
            </div>

            <div v-else class="form-container cards">
                <div class="item-container">
                    <div class="item-card-container" v-for="item in cart.relationships.blinds.data">
                        <item-card :item="item"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../components/Button";
import ItemCard from "@/components/calculators/ItemCard";
import LoadingScreen from "@/components/LoadingScreen";
import ConfirmModal from "@/components/modal/ConfirmModal";
import SaveQuoteModal from "@/components/quotes/SaveQuoteModal";

export default {
    name: "Cart",
    components: {LoadingScreen, ItemCard, Button},

    data() {
        return {
            cart: null,
            is_loading_cart: false
        }
    },
    computed: {
        cartTotal() {
            let total = 0;

            this.cart.relationships.blinds.data.forEach(blind => {
                total += blind.attributes.value;
            })

            return total;
        }
    },
    methods: {
        getCart() {
            if(!this.$store.getters.cart) return;

            this.is_loading_cart = true;
            this.$axios.get(`carts/${this.$store.getters.cart}`)
                .then(({data}) => {
                    this.cart = data.data;
                    let cartCounter = 0;

                    this.cart.relationships.blinds.data.sort((a,b) => a.attributes.order - b.attributes.order);

                    if(this.cart.relationships.blinds.data.length) {
                        this.cart.relationships.blinds.data.forEach(blind => {
                            blind.attributes.options = JSON.parse(blind.attributes.options);
                            cartCounter += 1;
                        })
                    }
                    this.$store.commit('SET_CART_COUNT', cartCounter);

                    this.is_loading_cart = false;
                })
                .catch(e => {
                    this.is_loading_cart = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_cart')),
                        type: 'error',
                    });
                });
        },
        toggleSaveQuoteModal() {
            this.$modal.show(
                SaveQuoteModal, {
                    title: this.$t('quotes.save_as_quote'),
                    confirmText: this.$t('save'),
                    confirmClass: '--primary',
                    value: this.cartTotal,
                    blinds: this.cart.relationships.blinds.data,
                    user_id: this.$store.getters.user.id
                },
                {
                    name: 'save-quote-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: '500px',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {

                        }
                    }
                }
            );
        }
    },
    mounted() {
        this.getCart();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.cart')
            },
        }
    }
}
</script>

<style lang="scss" scoped>

.page-container {
    @apply w-full h-full mx-auto bg-white px-8;

    & > .page-content-container {
        @apply flex flex-col max-w-6xl mx-auto w-full;

        & > .header-container {
            @apply flex flex-col py-8;

            @screen sm {
                @apply flex-row justify-between;
            }

            & > .left {
                @apply flex flex-row mb-8 w-full;

                @screen sm {
                    @apply mb-0;
                }

                & > a {
                    @apply my-auto mr-4;

                    & > svg {
                        @apply text-primary text-2xl cursor-pointer;

                        &:hover {
                            @apply text-primary-over;
                        }
                    }
                }

                & > h1 {
                    @apply font-bold text-2xl my-auto pr-2;

                }
            }

            & > .right {
                @apply flex flex-col;

                @screen sm {
                    @apply flex-row;
                }

                & > .price-container {
                    @apply border-2 border-grey-light rounded p-2 px-6 text-black font-bold text-2xl text-center mb-4;

                    @screen sm {
                        @apply mb-0;
                    }
                }

                & > .button {
                    @apply px-4 mb-4;
                    min-width: 10rem;

                    @screen sm {
                        @apply mb-0 ml-6;
                    }
                }
            }
        }

        & > .empty-cart-container {
            @apply flex flex-col rounded-xl bg-grey-form px-6 py-8;

            @screen md {
                @apply px-8;
            }

            @screen lg {
                @apply p-20;
            }

            & > p {
                @apply font-bold text-lg mx-auto text-center mb-16;
            }

            & > button {
                @apply mx-auto;
            }
        }

        & > .form-container {
            @apply bg-grey-form border-2 border-grey-form rounded-xl py-2 mb-8;

            @screen md {
                @apply py-8;
            }

            .form-title {
                @apply text-xl font-bold;

                @screen lg {
                    @apply ml-2;
                }
            }

            .form {
                @apply w-full mt-7;

                @screen lg {
                    @apply w-4/5 ml-2;
                }
            }

            & > .item-container {
                @apply flex flex-row flex-wrap px-2;

                .item-card-container {
                    @apply w-full py-4 px-4;

                    @screen md {
                        @apply w-1/2 p-3;
                    }
                }
            }
        }
    }

}
</style>