<template>
    <loading-screen v-if="is_loading_order"/>
    <div v-else-if="!is_loading_order && order_details && order_details.attributes" class="page-container">
        <div class="page-content-container">
            <div class="header-container">
                <div class="left">
                    <router-link :to="{name: 'my-orders'}"><font-awesome-icon :icon="['far', 'arrow-circle-left']"/></router-link>
                    <h1>{{ $t('cart.order_details') }}</h1>
                </div>

                <div class="right">
                    <button v-if="order_details.attributes.status === 'on_hold'" class="button --primary" @click="confirmChanges">{{ $t('cart.confirm_changes') }}</button>
                </div>
            </div>

            <div class="order-details-wrapper">
                <div class="order-details-container">
                    <div class="title-row">
                        <p>Order ID</p>
                        <p>Ordered</p>
                        <p>Status</p>
                        <p>Client</p>
                        <p>Sales Person</p>
                        <p>Value</p>
                    </div>

                    <div class="order-row">
                        <div class="details">
                            <p>{{order_details.attributes.reference}}</p>
                            <p>{{order_details.attributes.confirmed_at | date}}</p>

                            <p v-if="order_details.attributes.status !== 'on_hold'">{{orderStatus(order_details.attributes.status)}}</p>
                            <div v-else class="on-hold-label"><p><span>On Hold</span></p></div>

                            <p>{{order_details.attributes.client_details ? order_details.attributes.client_details.name : order_details.relationships.customer.data.attributes.company}}</p>
                            <p>{{order_details.relationships.customer.data.attributes.name}}</p>
                            <p>€{{order_details.attributes.value}}</p>
                        </div>

                        <div class="icons-container">
                            <font-awesome-icon @click="generateReport" :icon="['fas','print']" :content="$t('quotes.print')" :class="{disabled: is_printing}"/>
                            <font-awesome-icon @click="toggleDeleteOrder" class="delete-icon" :icon="['far','times-circle']" :class="{disabled: !acceptedStatuses.find(element => element.includes(order_details.attributes.status))}"/>
                        </div>
                    </div>
                </div>
            </div>

<!--            Comment to push code!-->
            <div class="form-container cards" v-if="blinds">
                <div class="item-container">
                    <div class="item-card-container" v-for="item in blinds">
                        <item-card :item="item" :dont_show_icons="!$store.getters.hasRole('administrator') && isAgentOrder && (order_details.attributes.status !== 'processing' && order_details.attributes.status !== 'on_hold')" :dont_show_edit="!$store.getters.hasRole('administrator') && (order_details.attributes.status !== 'processing' && order_details.attributes.status !== 'on_hold')" :dont_show_delete="!$store.getters.hasRole('administrator') && blinds.length == 1" :actions_only_on_error="false" :cart_id="isCartOrder ? order_details.relationships.cart.data.id : null" :quote_id="isQuoteOrder ? order_details.relationships.quote.data.id : null" :order_id="order_details.id"></item-card>
                    </div>
                </div>
            </div>

            <div class="price-breakdown-container" v-if="!isAgentOrder">
                <h1>{{$t('orders.order_cost_summary')}}</h1>

                <div class="details-container">
                    <div class="row">
                        <p class="label">{{$t('cart.sub_total_1')}}</p>
                        <div class="price">€{{blinds_total.toFixed(2)}}</div>
                    </div>

                    <div class="row" v-if="order_details.attributes.discount">
                        <p class="label">{{$t('cart.discount')}} ({{order_details.attributes.discount}}%)</p>
                        <div class="price">€{{(blinds_total * (order_details.attributes.discount / 100)).toFixed(2)}}</div>
                    </div>

                    <div class="row" v-if="order_details.attributes.installation">
                        <p class="label">{{$t('cart.installation')}}</p>
                        <div class="price">€{{order_details.attributes.installation.toFixed(2)}}</div>
                    </div>

                    <div class="row">
                        <p class="label">{{$t('cart.sub_total_2')}}</p>
                        <div class="price">€{{(blinds_total - (order_details.attributes.discount ? (blinds_total * (order_details.attributes.discount / 100)).toFixed(2) : 0) + order_details.attributes.installation).toFixed(2)}}</div>
                    </div>

                    <div class="row" v-if="order_details.attributes.deposit">
                        <p class="label">{{$t('cart.deposit')}}</p>
                        <div class="price">€{{order_details.attributes.deposit.toFixed(2)}}</div>
                    </div>

                    <div class="row top-border">
                        <p class="label">{{$t('cart.balance_inc_vat')}}</p>
                        <div class="price">€{{order_details.attributes.value}}</div>
                    </div>
                </div>
            </div>

            <div class="sales-person-container" v-if="!isAgentOrder && order_details && order_details.relationships && order_details.relationships.customer && order_details.relationships.customer.data">
                <h1>{{$t('cart.sales_person')}}</h1>
                <Form class="form-container">
                    <FormGroupTwo>
                        <FormInputText v-model="order_details.relationships.customer.data.attributes.company" identifier="company_name" :label="$t('users.company_name')" :placeholder="$t('users.company_name')" disabled/>
                        <FormInputText v-model="order_details.relationships.customer.data.attributes.name" identifier="name" :label="$t('users.name')" :placeholder="$t('users.name')" disabled/>
                    </FormGroupTwo>
                    <FormInputText v-model="order_details.relationships.customer.data.attributes.email" identifier="email_address" :label="$t('users.email_address')" :placeholder="$t('users.email_address')" disabled/>
                </Form>
            </div>

            <div class="billing-details-container" v-if="order_details && order_details.relationships && order_details.relationships.customer && order_details.relationships.customer.data">
                <h1>{{isAgentOrder ? $t('cart.agent_invoicing_details') : $t('cart.client_invoicing_details')}}</h1>

                <Form class="form-container">
                    <div class="form-wrapper">
                        <FormGroupTwo>
                            <FormInputText v-model="isAgentOrder ? order_details.relationships.customer.data.attributes.name : order_details.attributes.client_details.name" identifier="name" :label="$t('users.name')" :placeholder="$t('users.name')" disabled/>
                            <FormInputText v-model="isAgentOrder ? order_details.relationships.customer.data.attributes.phone : order_details.attributes.client_details.phone" identifier="phone" :label="$t('users.contact_number')" :placeholder="$t('users.contact_number')" disabled/>
                        </FormGroupTwo>

                        <FormInputText v-model="isAgentOrder ? order_details.relationships.customer.data.attributes.address : order_details.attributes.client_details.address" identifier="address" :label="$t('users.address')" :placeholder="$t('users.address')" disabled/>
                        <FormInputText v-model="isAgentOrder ? order_details.relationships.customer.data.attributes.email : order_details.attributes.client_details.email" identifier="email_address" :label="$t('users.email_address')" :placeholder="$t('users.email_address')" disabled/>

                        <FormGroupTwo v-if="!isAgentOrder">
                            <FormInputText v-model="order_details.attributes.discount" identifier="discount" :label="`${$t('cart.discount')} (%)`" :placeholder="$t('cart.discount')" type="number" disabled/>
                            <FormInputText v-model="order_details.attributes.installation" identifier="installation" :label="`${$t('users.installation')} (€)`" :placeholder="$t('users.installation')" type="number" disabled/>
                        </FormGroupTwo>
                    </div>

                    <div class="form-wrapper">
                        <FormGroupTwo>
                            <FormInputText v-model="isAgentOrder ? order_details.relationships.customer.data.attributes.company : order_details.attributes.client_details.company" identifier="company_name" :label="$t('users.company_name')" :placeholder="$t('users.company_name')" disabled/>
                            <FormInputText v-model="isAgentOrder ? order_details.relationships.customer.data.attributes.vat_num : order_details.attributes.client_details.vat_num" identifier="vat_number" :label="$t('users.vat_number')" :placeholder="$t('users.vat_number')" disabled/>
                        </FormGroupTwo>

                        <FormInputText v-model="order_details.attributes.note" identifier="note" :label="$t('calculators.note')" :placeholder="$t('calculators.note')" :useTextarea="true" :largeTextarea="true" disabled/>

                        <FormGroupTwo v-if="!isAgentOrder" >
                            <FormInputText v-model="order_details.attributes.deposit" identifier="deposit" :label="`${$t('users.deposit')} (€)`" :placeholder="$t('users.deposit')" type="number" disabled/>
                        </FormGroupTwo>
                    </div>
                </Form>
            </div>
            <vue-html2pdf
                v-if="order_details"
                :float-layout="true"
                :show-layout="false"
                :enable-download="true"
                :filename="`${order_details.attributes.reference}`"
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="landscape"
                pdf-content-width="1120px"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)"
                ref="html2Pdf"
            >
                <section slot="pdf-content" class="pdf-content">
                    <div v-for="(array, index) in printing_pages">
                        <div class="top-row">
                            <h1 class="reference">{{ order_details.attributes.reference }} - Blinds Qty {{ blinds.length }} - Page {{ index + 1 }} / {{ printing_pages.length }} </h1>

                            <div class="sales-person-container" v-if="index === 0 && !isAgentOrder">
                                <p><span>SP:</span> {{ order_details.relationships.customer.data.attributes.name }}</p>
                                <p><span>Company:</span> {{ order_details.relationships.customer.data.attributes.company }}</p>
                            </div>
                        </div>

                        <div class="order-details-container" v-if="order_details && order_details.relationships && order_details.relationships.customer && order_details.relationships.customer.data && index === 0">
                            <div class="billing-details-container">
                                <div class="card-title-container">
                                    <h1 class="card-title">{{isAgentOrder ? $t('cart.agent_invoicing_details') : $t('cart.client_invoicing_details')}}</h1>
                                </div>

                                <div class="user-details-wrapper">
                                    <div class="user-details-container">
                                        <div class="user-detail">
                                            <p class="label">{{ $t('users.date') }}:</p>
                                            <p>{{ $moment.utc(order_details.attributes.created_at).local().format('DD/MM/YYYY - HH:mm') }}</p>
                                        </div>

                                        <div class="user-detail">
                                            <p class="label">{{ $t('users.name') }}:</p>
                                            <p>{{ isAgentOrder ? order_details.relationships.customer.data.attributes.name : order_details.attributes.client_details.name }}</p>
                                        </div>

                                        <div class="user-detail">
                                            <p class="label">{{ $t('users.contact') }}:</p>
                                            <p>{{ isAgentOrder ? order_details.relationships.customer.data.attributes.phone : order_details.attributes.client_details.phone }}</p>
                                        </div>
                                    </div>

                                    <div class="user-detail">
                                        <p class="label">{{ $t('users.address') }}:</p>
                                        <p>{{ isAgentOrder ? order_details.relationships.customer.data.attributes.address : order_details.attributes.client_details.address }}</p>
                                    </div>

                                    <div class="user-detail">
                                        <p class="label">{{ $t('users.email_address') }}:</p>
                                        <p>{{ isAgentOrder ? order_details.relationships.customer.data.attributes.email : order_details.attributes.client_details.email }}</p>
                                    </div>

                                    <div class="user-details-container" v-if="(isAgentOrder && order_details.relationships.customer.data.attributes.company || order_details.relationships.customer.data.attributes.vat_num) || (!isAgentOrder && order_details.attributes.client_details.company || order_details.attributes.client_details.vat_num)">
                                        <div class="user-detail" v-if="(isAgentOrder && order_details.relationships.customer.data.attributes.company) || (!isAgentOrder && order_details.attributes.client_details.company)">
                                            <p class="label" >{{ $t('users.company_name') }}:</p>
                                            <p>{{ isAgentOrder ? order_details.relationships.customer.data.attributes.company : order_details.attributes.client_details.company }}</p>
                                        </div>

                                        <div class="user-detail" v-if="(isAgentOrder && order_details.relationships.customer.data.attributes.vat_num) || (!isAgentOrder && order_details.attributes.client_details.vat_num)">
                                            <p class="label" >{{ $t('users.vat_number') }}:</p>
                                            <p>{{ isAgentOrder ? order_details.relationships.customer.data.attributes.vat_num : order_details.attributes.client_details.vat_num }}</p>
                                        </div>
                                    </div>

                                    <div class="user-detail" v-if="order_details.attributes.note">
                                        <p class="label">{{ $t('cart.note') }}:</p>
                                        <p v-if="order_details.attributes.note">{{ order_details.attributes.note }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="price-breakdown-container" v-if="!isAgentOrder">
                                <div class="card-title-container">
                                    <h1 class="card-title">{{$t('cart.order_cost_summary')}}</h1>
                                </div>

                                <div class="details-container">
                                    <div class="row">
                                        <p class="label">{{$t('cart.sub_total_1')}}</p>
                                        <div class="price">€{{blinds_total.toFixed(2)}}</div>
                                    </div>

                                    <div class="row" v-if="order_details.attributes.discount">
                                        <p class="label">{{$t('cart.discount')}} ({{order_details.attributes.discount}}%)</p>
                                        <div class="price">€{{(blinds_total * (order_details.attributes.discount / 100)).toFixed(2)}}</div>
                                    </div>

                                    <div class="row" v-if="order_details.attributes.installation">
                                        <p class="label">{{$t('cart.installation')}}</p>
                                        <div class="price">€{{order_details.attributes.installation.toFixed(2)}}</div>
                                    </div>

                                    <div class="row">
                                        <p class="label">{{$t('cart.sub_total_2')}}</p>
                                        <div class="price">€{{(blinds_total - (order_details.attributes.discount ? (blinds_total * (order_details.attributes.discount / 100)).toFixed(2) : 0) + order_details.attributes.installation).toFixed(2)}}</div>
                                    </div>

                                    <div class="row bottom-border" v-if="order_details.attributes.deposit">
                                        <p class="label">{{$t('cart.deposit')}}</p>
                                        <div class="price">€{{order_details.attributes.deposit.toFixed(2)}}</div>
                                    </div>

                                    <div class="row">
                                        <p class="label">{{$t('cart.balance_inc_vat')}}</p>
                                        <div class="price">€{{order_details.attributes.value}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item-cards-container">
                            <div class="item-card-container" v-for="item in array">
                                <item-card-printing class="item-card-wrapper" :item="item"/>
                            </div>
                        </div>

                        <div v-if="index !== printing_pages.length-1" class="html2pdf__page-break"/>
                    </div>
                </section>
            </vue-html2pdf>
        </div>
    </div>
</template>

<script>
import Button from "../../../components/Button";
import ConfirmModal from "@/components/modal/ConfirmModal";
import Form from "@/components/form/Form";
import FormGroupTwo from "@/components/form/FormGroupTwo";
import FormInputText from "@/components/form/FormInputText";
import ItemCard from "@/components/calculators/ItemCard";
import moment from "moment";
import LoadingScreen from "@/components/LoadingScreen";
import ItemCardPrinting from "@/components/calculators/ItemCardPrinting";
import _ from "lodash";
import VueHtml2pdf from 'vue-html2pdf';

export default {
    name: "Checkout",
    components: {ItemCardPrinting, LoadingScreen, ItemCard, FormInputText, FormGroupTwo, Form, Button, VueHtml2pdf},
    data() {
        return {
            acceptedStatuses: ['processing', 'on_hold'],
            order_details: {},
            blinds: [],
            blinds_total: 0,
            printing_pages: [],
            cart: null,
            is_loading_order: false,
            is_printing: false,
        }
    },
    computed: {
        isAgentOrder() {
            return !this.order_details.attributes.client_details;
        },
        isCartOrder() {
            return this.order_details.relationships.cart_blinds.data.length > 0;
        },
        isQuoteOrder() {
            return this.order_details.relationships.quote_blinds.data.length > 0;
        }
    },
    methods: {
        orderStatus(statusValue){
            return _.startCase(statusValue)
        },
        async generateReport() {
            this.is_printing = true;
            await this.$refs.html2Pdf.generatePdf()
        },
        async beforeDownload() {
            this.is_printing = true;
        },
        async hasDownloaded(){
            this.is_printing = false;
        },
        confirmChanges() {
            this.$axios.patch(`orders/${this.$route.params.id}`, {status: 'revised'}).then(({data}) => {
                this.$router.push({name: 'my-orders'});
            }).catch(e => {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('cart.error_cancel_order')),
                    type: 'error',
                });
            });
        },
        toggleDeleteOrder() {
            if(!this.acceptedStatuses.find(element => element.includes(this.order_details.attributes.status))) return;

            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('cart.cancel_order'),
                    message: this.$t('cart.are_you_sure_cancel_order'),
                    confirmText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.$axios.patch(`orders/${this.$route.params.id}`, {status: 'cancelled'}).then(({data}) => {
                                this.$router.push({name: 'my-orders'});
                            }).catch(e => {
                                this.$notify({
                                    title: this.$t('error'),
                                    text: this.$larerror(e.response.data.errors, this.$t('cart.error_cancel_order')),
                                    type: 'error',
                                });
                            });
                        }
                    }
                }
            );
        },
        async updateOrderValue() {
            this.is_loading_order = true;

            await this.$axios.patch(`orders/${this.$route.params.id}`, {value: (this.blinds_total - (this.order_details.attributes.discount ? (this.blinds_total * (this.order_details.attributes.discount / 100)).toFixed(2) : 0) + (this.order_details.attributes.installation ? parseInt(this.order_details.attributes.installation) : 0) - (this.order_details.attributes.deposit ? parseInt(this.order_details.attributes.deposit) : 0)).toFixed(2)})
                .then(({data}) => {
                    this.order_details.attributes.value = data.data.attributes.value;
                    this.is_loading_order = false;
                })
                .catch(e => {
                    this.is_loading_order = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_order')),
                        type: 'error',
                    });
                });
        },
        async getOrder() {
            this.is_loading_order = true;

            this.$axios.get(`orders/${this.$route.params.id}`)
                .then(async ({data}) => {
                    this.order_details = data.data;

                    if(data.data.relationships.cart_blinds.data.length > 0) {
                        this.blinds = data.data.relationships.cart_blinds.data;
                    }
                    else if(data.data.relationships.quote_blinds.data.length > 0) {
                        this.blinds = data.data.relationships.quote_blinds.data;
                    }

                    this.blinds.sort((a,b) => a.attributes.order - b.attributes.order);

                    if(this.blinds.length) {
                        this.blinds.forEach(blind => {
                            blind.attributes.options = JSON.parse(blind.attributes.options);
                            this.blinds_total += blind.attributes.value;
                        })
                    }
                    
                    this.printing_pages = [];
                    let temp_blinds_printing_array = _.clone(this.blinds);

                    for(let i = 0; i < this.blinds.length / 3; i++) {
                        if(i === 0 && temp_blinds_printing_array.slice(0, 3).find(blind => blind.attributes.type === 'curtain_blind')) {
                            this.printing_pages.push([]);
                        }

                        this.printing_pages.push(temp_blinds_printing_array.splice(0, 3));
                    }

                    await this.updateOrderValue();

                    this.is_loading_order = false;
                })
                .catch(e => {
                    this.is_loading_order = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_order')),
                        type: 'error',
                    });
                });
        }
    },
    filters: {
        date(value) {
            return moment(value).format('DD-MM-YYYY');
        },
        dateTime(value) {
            return moment(value).format('DD-MM-YYYY hh:mm:ss A');
        }
    },
    async mounted() {
        await this.getOrder();
    }
}
</script>

<style lang="scss" scoped>

@media print {
    @page {
        size: landscape
    }
}

.pdf-content {
    @apply px-8 py-2;

    .top-row {
        @apply flex flex-row justify-between mb-4;

        .reference {
            @apply text-black font-bold text-2xl my-auto -mt-1;
        }

        .sales-person-container {
            @apply flex flex-row border-1 border-black rounded-md py-4 px-2 bg-grey-light mt-1;

            p {
                @apply text-sm px-2 -mt-3;

                &.title {
                    @apply font-bold;
                }

                span {
                    @apply font-bold;
                }
            }
        }
    }

    .item-cards-container {
        @apply flex flex-row -mx-2;

        .item-card-container {
            @apply w-1/3 px-2 mt-2;
        }
    }

    .order-details-container {
        @apply flex flex-row mb-2;

        .billing-details-container {
            @apply flex flex-col rounded-md border-1 border-black bg-grey-light mr-2;
            width: 60%;

            .card-title-container {
                @apply px-4 py-1 bg-black text-white;

                .card-title {
                    @apply font-bold text-sm -mt-2 mb-2;
                }
            }

            .user-details-wrapper {
                @apply px-4 py-2;

                .user-details-container {
                    @apply flex flex-row;
                }

                .user-detail {
                    @apply flex flex-row mr-8;

                    p {
                        &:nth-child(even) {
                            @apply ml-1;
                        }
                    }

                    &:last-child {
                        @apply mr-0;
                    }

                    .label {
                        @apply font-bold mb-2 text-xs;
                    }

                    p {
                        @apply mb-2 text-xs;
                    }
                }
            }

            & > h1 {
                @apply font-bold text-lg mb-4;
            }
        }

        .price-breakdown-container {
            @apply ml-2 rounded-md border-1 border-black bg-grey-light;
            width: 40%;

            .card-title-container {
                @apply px-4 py-1 bg-black text-white;

                .card-title {
                    @apply font-bold text-sm -mt-2 mb-2;
                }
            }

            .details-container {
                @apply flex flex-col flex-wrap max-h-40 px-4 py-2 -mx-4;

                .row {
                    @apply flex flex-row text-xs justify-between mx-4 mb-4;

                    .label {
                        @apply font-bold mr-4;
                    }

                    &.bottom-border {
                        @apply pb-4 mb-0 border-b border-black;
                    }
                }
            }
        }
    }
}

.page-container {
    @apply w-full h-full mx-auto bg-white px-8;

    & > .page-content-container {
        @apply flex flex-col max-w-6xl mx-auto w-full;

        & > .header-container {
            @apply flex flex-row justify-between py-8;

            & > .left {
                @apply flex flex-row;

                & > a {
                    @apply my-auto mr-4;

                    & > svg {
                        @apply text-primary text-2xl cursor-pointer;

                        &:hover {
                            @apply text-primary-over;
                        }
                    }
                }

                & > h1 {
                    @apply font-bold text-2xl my-auto;
                }
            }

            & > .right {
                @apply flex flex-row;

                & > .price-container {
                    @apply border-2 border-grey-light rounded p-2 px-6 mr-6 text-black font-bold text-2xl;
                }

            }
        }

        & > .order-details-wrapper {
            @apply overflow-x-scroll mb-8;

            @screen md {
                @apply overflow-x-auto;
            }

            & > .order-details-container {
                @apply flex flex-col mt-12;
                width: 65rem;

                @screen md {
                    @apply w-full;
                }

                & > .title-row {
                    @apply flex flex-row mb-4 ml-12;
                    width: 55rem;

                    @screen lg {
                        @apply w-5/6;
                    }

                    & > p {
                        @apply w-1/4 font-bold text-left text-base;
                    }
                }

                & > .order-row {
                    @apply flex flex-row w-full bg-grey-form rounded-xl py-6;

                    & > .details {
                        @apply flex flex-row ml-12 capitalize;
                        width: 55rem;

                        @screen lg {
                            @apply w-5/6;
                        }

                        & > p {
                            @apply w-1/4 font-bold text-left text-base text-grey-dark;
                        }

                        & > .on-hold-label {
                            @apply w-1/4 font-bold text-left text-base text-grey-dark h-full;

                            & > p {
                                @apply text-white bg-primary text-base px-2 rounded font-medium text-center h-full flex;
                                width: fit-content;

                                & > span {
                                    @apply my-auto;
                                }
                            }
                        }
                    }

                    & > .icons-container {
                        @apply flex flex-row w-auto ml-auto;

                        & > svg {
                            @apply text-2xl text-grey-dark cursor-pointer ml-4;

                            &:hover {
                                @apply text-grey-darker;
                            }
                        }

                        & > .delete-icon {
                            @apply text-primary mx-4 mr-8;

                            &:hover {
                                @apply text-primary-over;
                            }

                            &.disabled {
                                @apply text-primary-dimmed cursor-not-allowed;
                            }
                        }
                    }
                }
            }
        }

        & > .price-breakdown-container {
            @apply flex flex-col rounded-xl bg-grey-form p-8 pb-6 mb-10;

            & > h1 {
                @apply font-bold text-xl mb-6;
            }

            .details-container {
                @apply flex flex-col px-4 py-2;

                .row {
                    @apply flex flex-row text-sm mb-4 w-56 justify-between;

                    .label {
                        @apply font-bold;
                    }

                    &.top-border {
                        @apply border-t-2 border-primary pt-3;
                    }
                }
            }
        }

        & > .sales-person-container {
            @apply flex flex-col rounded-xl bg-grey-form p-8 pb-6 mb-10;

            & > h1 {
                @apply font-bold text-xl mb-6;
            }

            & > .form-container {
                @apply flex flex-col bg-grey-form;

                @screen lg {
                    @apply flex flex-row;
                }

                & > .input-group-two {
                    @screen lg {
                        @apply w-1/2 mr-1;
                    }
                }

                & > .input-group {
                    @screen lg {
                        @apply w-1/2 ml-1;
                    }
                }

                .form-wrapper {
                    @screen lg {
                        @apply w-1/2 mx-2;
                    }
                }
            }
        }

        & > .billing-details-container {
            @apply flex flex-col rounded-xl bg-grey-form p-8 pb-6 mb-10;

            & > h1 {
                @apply font-bold text-xl mb-6;
            }

            & > .form-container {
                @apply flex flex-col -mx-2 bg-grey-form;

                @screen lg {
                    @apply flex flex-row;
                }

                .form-wrapper {
                    @apply w-full;

                    @screen lg {
                        @apply w-1/2 mx-2;
                    }
                }
            }
        }

        & > .form-container {
            @apply bg-grey-form border-2 border-grey-form rounded-xl py-8 mb-8;

            & > .item-container {
                @apply flex flex-row flex-wrap w-full px-3;

                .item-card-container {
                    @apply w-full py-5 px-4;

                    @screen md {
                        @apply w-1/2 p-3;
                    }
                }
            }
        }
    }

}
</style>