<template>
    <loading-screen v-if="is_loading_blind || is_loading_fabrics || is_loading_articles"></loading-screen>
    <div v-else class="page-container">
        <div class="header-container">
            <div class="title-container" v-if="blind_details">
                <router-link :to="{name: 'calculators-index'}">
                    <font-awesome-icon :icon="['far', 'arrow-circle-left']"/>
                </router-link>
                <h1>{{blind_details.attributes.name}}</h1>
            </div>
            <div class="price-container">
                <div class="price">
                    <p>€{{totalCost}}</p>
                </div>
                <Button type="submit" className="--primary --small" :onclick="addToCart" :class="{'spinner-black': is_adding_item}">
                    {{$route.query.token ? $t('calculators.update') : ($route.query.quote ? $t('calculators.add_to_quote') : $t('calculators.add_to_cart'))}}
                </Button>
            </div>
        </div>
        <div class="form-container">
            <h1 class="form-title">{{$t('calculators.measurements')}}</h1>

            <FormGroupFour class="form main-form">
                <FormInputText v-model="$v.blind.width.$model" type="number" identifier="width" :label="$t('calculators.width') + ' (cm)'"
                               :placeholder="$t('calculators.width')" borderColor="primary" :max="240" :has-error="$v.blind.width.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.blind.width.required">
                            {{$t('validation.x_is_required',{x: $t('calculators.width')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.blind.drop.$model" type="number" identifier="drop" :label="$t('calculators.drop') + ' (cm)'"
                               :placeholder="$t('calculators.drop')" borderColor="primary" :has-error="$v.blind.drop.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.blind.drop.required">
                            {{$t('validation.x_is_required',{x: $t('calculators.drop')})}}
                        </p>
                    </template>
                </FormInputText>
            </FormGroupFour>

            <FormGroupFour class="form">
                <FormInputSelect v-model="$v.blind.recess_type.$model" type="number" identifier="recess-type" :label="$t('calculators.recess_type')"
                                 :placeholder="$t('calculators.recess_type')" :options="recessTypeOptions" :has-error="$v.blind.recess_type.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.blind.recess_type.required">
                            {{$t('validation.x_is_required',{x: $t('calculators.recess_type')})}}
                        </p>
                    </template>
                </FormInputSelect>

                <FormInputSelect v-model="$v.blind.width_clearance.$model" type="number" identifier="width-clearance" :label="$t('calculators.width_clearance')"
                                 :placeholder="$t('calculators.width_clearance')" :options="clearanceOptions" :has-error="$v.blind.width_clearance.$error" :disabled="blind.recess_type === 'Outside recess'">
                    <template v-slot:errors>
                        <p v-if="!$v.blind.width_clearance.required">
                            {{$t('validation.x_is_required',{x: $t('calculators.width_clearance')})}}
                        </p>
                    </template>
                </FormInputSelect>

                <FormInputSelect v-model="$v.blind.chain_side.$model" type="number" identifier="chain-side" :label="$t('calculators.chain_side')"
                                 :placeholder="$t('calculators.chain_side')" :options="chainSideOptions" :has-error="$v.blind.chain_side.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.blind.chain_side.required">
                            {{$t('validation.x_is_required',{x: $t('calculators.chain_side')})}}
                        </p>
                    </template>
                </FormInputSelect>

                <FormInputSelect v-model="$v.blind.chain_type.$model" type="number" identifier="chain-type" :label="$t('calculators.chain_type')"
                                 :placeholder="$t('calculators.chain_type')" :options="chainTypeOptions" :has-error="$v.blind.chain_type.$error"
                                 :display-custom-label="(row) => `${row.attributes.name}`" :disabled="!!motor_selected">
                    <template v-slot:errors>
                        <p v-if="!$v.blind.chain_type.required">
                            {{$t('validation.x_is_required',{x: $t('calculators.chain_type')})}}
                        </p>
                    </template>
                </FormInputSelect>
            </FormGroupFour>

            <div class="divider"></div>

            <div class="form-wrapper form">
                <FormGroupTwo class="textboxes-container">
                    <FormInputText v-model="$v.blind.tile_cut_width.$model" type="number" identifier="tile-cut-width"
                                   :label="$t('calculators.tile_cut_width') + ' (cm)'" :has-error="$v.blind.tile_cut_width.$error"
                                   :placeholder="$t('calculators.tile_cut_width')">
                        <template v-slot:errors>
                        </template>
                    </FormInputText>
                    <FormInputSelect v-model="$v.blind.tile_cut_width_clearance.$model" type="number" identifier="tile-cut-width-clearance" :label="$t('calculators.tile_cut_width_clearance')"
                                     :placeholder="$t('calculators.tile_cut_width_clearance')" :options="tileCutWidthClearanceOptions" :has-error="$v.blind.tile_cut_width_clearance.$error">
                        <template v-slot:errors>
                        </template>
                    </FormInputSelect>
                </FormGroupTwo>

                <FormInputText class="note-field" v-model="note" identifier="note" :label="$t('calculators.note')"
                               :placeholder="$t('calculators.note')" :useTextarea="true" :largeTextarea="true"></FormInputText>
            </div>
        </div>

        <div class="form-container cards">
            <h1 class="form-title">{{$t('calculators.fabrics')}}</h1>
            <div class="item-container">
                <div class="item-card-container" v-for="fabric in fabrics">
                    <div class="item-card" @click="onFabricSelect(fabric)" :class="{'selected': fabric_selected === fabric}">
                        <h1 class="item-card-title">{{fabric.attributes.name}}</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-container cards" v-if="fabric_selected">
            <h1 class="form-title">{{$t('calculators.fabric_colours')}}</h1>
            <div class="item-container">
                <div class="item-card-container" v-for="colour in JSON.parse(fabric_selected.attributes.colors)">
                    <div class="item-card" @click="onColourSelect(colour)" :class="{'selected': colour_selected === colour}">
                        <h1 class="item-card-title">{{colour}}</h1>
                    </div>
                </div>
            </div>
            <FormInputText class="custom-colour-field" v-show="colour_selected === 'Custom Colour'" v-model="$v.custom_colour_code.$model"
                           type="text" identifier="custom-colour-code" :label="$t('calculators.custom_colour_code')"
                           :placeholder="$t('calculators.custom_colour_code')" :has-error="$v.custom_colour_code.$error">
                <template v-slot:errors>
                    <p v-if="!$v.custom_colour_code.required">
                        {{$t('validation.x_is_required',{x: $t('calculators.custom_colour_code')})}}
                    </p>
                </template>
            </FormInputText>
        </div>

        <div class="form-container cards">
            <h1 class="form-title">{{$t('calculators.aluminium_cover')}}</h1>
            <div class="item-container">
                <div class="item-card-container" v-for="cover in aluminium_cover_options">
                    <div class="item-card" @click="onCoverSelect(cover)" :class="{'selected': cover_option_selected === cover}">
                        <h1 class="item-card-title">{{cover.name}}</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-container cards">
            <h1 class="form-title">Home Automation</h1>
            <div class="category-container">
                <h2>Motors</h2>

                <div class="item-container">
                    <div class="item-card-container" v-for="motor in motors">
                        <div class="item-card" @click="onMotorSelected(motor)" :class="{'selected': motor === motor_selected, 'disabled': motor.disabled}">
                            <h1 class="item-card-title">{{motor.attributes.name}}</h1>
                        </div>
                    </div>
                </div>

                <div v-if="controller_types.length > 0" class="divider"></div>

                <h2>Controller Type</h2>

                <div class="item-container">
                    <div class="item-card-container" v-for="controller_type in controller_types">
                        <div class="item-card" @click="onControllerTypeSelected(controller_type)" :class="{'selected': controller_types_selected.find(c => c.attributes.code === controller_type.attributes.code)}">
                            <h1 class="item-card-title">{{controller_type.attributes.name}}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormInputText from "@/components/form/FormInputText";
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormGroupThree from "../../components/form/FormGroupThree";
import Button from "../../components/Button";
import FormGroupFour from "@/components/form/FormGroupFour";
import FormInputSelect from "@/components/form/FormInputSelect";
import LoadingScreen from "@/components/LoadingScreen";
import {minValue, required, requiredIf} from 'vuelidate/lib/validators'
import _ from "lodash";

export default {
    name: "ZebraBlind",
    components: {LoadingScreen, FormInputSelect, FormGroupFour, FormInputText, FormGroupTwo, Button, FormGroupThree},
    data() {
        return {
            blind: {
                width: null,
                drop: null,
                recess_type: null,
                width_clearance: null,
                chain_side: null,
                chain_type: null,
                tile_cut_width: null,
                tile_cut_width_clearance: null,
            },
            note: null,

            recessTypeOptions: ['Outside recess', 'Inside recess'],
            clearanceOptions: ['0.5 cm', '1 cm', '1.5 cm', '2 cm'],
            tileCutWidthClearanceOptions: ['0.5 cm', '1 cm', '1.5 cm', '2 cm'],
            chainSideOptions: ['Left', 'Right'],
            chainTypeOptions: [],
            aluminium_cover_options: [
                {
                    name: 'With Cover',
                    type: 'with_cover'
                },
                {
                    name: 'Back Bar Only Without Cover',
                    type: 'without_cover'
                }
            ],
            fabrics: [],
            fabric_selected: null,
            colour_selected: null,
            custom_colour_code: null,
            cover_option_selected: null,

            motors: [],
            controller_types: [],
            motor_selected: null,
            controller_types_selected: [],
            per_cm_items: [],
            cover_per_cm_items: [],
            backbar_per_cm_items: [],
            per_unit_items: [],
            cover_per_unit_items: [],
            backbar_per_unit_items: [],
            aluminium_cover: null,
            blind_details: null,
            order_details: null,
            articles: [],

            cart: null,

            is_loading_blind: false,
            is_loading_articles: false,
            is_loading_fabrics: false,
            is_adding_item: false,
            is_updating_item: false,
            is_loading_cart: false,
        }
    },
    validations: {
        blind: {
            width: {
                required,
            },
            drop: {
                required,
            },
            recess_type: {required},
            width_clearance: {
                required: requiredIf(function () {
                    return this.blind.recess_type === 'Inside recess';
                })
            },
            chain_side: {required},
            chain_type: {
                required: requiredIf(function () {
                    return !this.motor_selected;
                })
            },
            tile_cut_width: {
            },
            tile_cut_width_clearance: {
                required: requiredIf(function () {
                    return this.blind.tile_cut_width;
                })
            }
        },
        custom_colour_code: {
            required: requiredIf(function () {
                return this.colour_selected === 'Custom Colour';
            })
        }
    },
    watch: {
        blind: {
            handler(newValue, oldValue) {
                if(newValue.recess_type === 'Outside recess') {
                    this.blind.width_clearance = null;
                }
            },
            deep: true
        }
    },
    methods: {
        onFabricSelect(fabric) {
            this.fabric_selected = fabric;
        },
        onColourSelect(colour) {
            this.colour_selected = colour;
        },
        onMotorSelected(motor) {
            if(motor === this.motor_selected) {
                this.motor_selected = null;
            }
            else {
                this.motor_selected = motor;

                //Disable and clear chain type if any motor is selected
                this.blind.chain_type = null;
            }
        },
        onControllerTypeSelected(controller_type) {
            if(!this.controller_types_selected.find(c => c.attributes.code === controller_type.attributes.code))
                this.controller_types_selected.push(controller_type);
            else {
                this.controller_types_selected.splice(this.controller_types_selected.findIndex(c => c.attributes.code === controller_type.attributes.code), 1);
            }
        },
        onCoverSelect(cover) {
            this.cover_option_selected = cover;
        },
        unitsCM2(fabric) {
            return +(Math.round((this.blind.drop * this.blind.width * fabric.attributes.multiplier) + "e+2") + "e-2");
        },
        async retrieveBlind() {
            this.is_loading_blind = true;

            await this.$axios.get(`blinds/${this.$route.query.id}`).then(({data}) => {
                this.blind_details = data.data;
                this.is_loading_blind = false;
            }).catch(e => {
                this.is_loading_blind = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_blind')),
                    type: 'error',
                });
            });
        },
        async retrieveFabrics() {
            this.is_loading_fabrics = true;

            await this.$axios.get(`blinds/${this.$route.query.id}/fabrics`).then(({data}) => {
                this.fabrics = data.data.sort((a,b) => a.attributes.order - b.attributes.order);
                this.is_loading_fabrics = false;
            }).catch(e => {
                this.is_loading_fabrics = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_fabrics')),
                    type: 'error',
                });
            });
        },
        async retrieveArticles() {
            this.is_loading_articles = true;

            await this.$axios.get(`blinds/${this.$route.query.id}/articles/list`).then(({data}) => {
                this.articles = data.data;

                this.articles.forEach(article => {
                    if(article.attributes.unit === 'per_cm') {
                        if(article.relationships.category.data.length === 0 || (article.relationships.category && article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name !== 'Small Aluminium Cassette' && article.relationships.category.data.attributes.name !== 'Large Aluminium Cassette' && article.relationships.category.data.attributes.name !== 'Aluminium Backbar' && article.relationships.category.data.attributes.name !== 'Chain Types'))
                            this.per_cm_items.push(article);
                        else if(article.relationships.category.data.attributes.name === 'Small Aluminium Cassette' || article.relationships.category.data.attributes.name === 'Large Aluminium Cassette')
                            this.cover_per_cm_items.push(article);
                        else if(article.relationships.category.data.attributes.name === 'Aluminium Backbar')
                            this.backbar_per_cm_items.push(article);
                    }

                    if(article.attributes.unit === 'per_unit') {
                        if(article.relationships.category.data.length === 0 || (article.relationships.category && article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name !== 'Small Aluminium Cassette' && article.relationships.category.data.attributes.name !== 'Large Aluminium Cassette' && article.relationships.category.data.attributes.name !== 'Aluminium Backbar' && article.relationships.category.data.attributes.name !== 'Chain Types'))
                            this.per_unit_items.push(article);
                        else if(article.relationships.category.data.attributes.name === 'Small Aluminium Cassette' || article.relationships.category.data.attributes.name === 'Large Aluminium Cassette')
                            this.cover_per_unit_items.push(article);
                        else if(article.relationships.category.data.attributes.name === 'Aluminium Backbar')
                            this.backbar_per_unit_items.push(article);
                    }

                    if(article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Motors')
                        this.motors.push(article);

                    if(article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Controller Types')
                        this.controller_types.push(article);

                    if(article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Chain Types')
                        this.chainTypeOptions.push(article);
                });

                this.is_loading_articles = false;
            }).catch(e => {
                this.is_loading_articles = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_articles')),
                    type: 'error',
                });
            });
        },
        async getOrder() {
            let order = 0;

            if(!this.$route.query.token) {
                if(this.$route.query.quote) {
                    let order = 0;

                    await this.$axios.get(`/quotes/${this.$route.query.quote}`).then(({data}) => {
                        if(data.data.relationships.blinds.data.length)
                            data.data.relationships.blinds.data.forEach(blind => {
                                if(blind.attributes.order > order)
                                    order = blind.attributes.order;
                            })
                    });

                    return order += 1;
                } else if(this.cart) {
                    this.cart.relationships.blinds.data.forEach(blind => {
                        if(blind.attributes.order > order)
                            order = blind.attributes.order;
                    })
                }

                return order += 1;
            } else {
                return order = this.$route.query.cart_order ? this.$route.query.cart_order : 0;
            }
        },
        async updateCartOrder(payload) {
            await this.$axios.put(`carts/${this.$route.query.cart}/blinds/detach`,  {token: this.$route.query.token})
                .then(({data}) => {})
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                        type: 'error',
                    });
                });

            await this.$axios.put(`carts/${this.$route.query.cart}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {
                this.is_adding_item = false;
            }).catch(e => {
                this.is_adding_item = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
                    type: 'error',
                });
            });
        },
        async updateQuote(payload) {
            this.is_adding_item = true;

            if(this.$route.query.token)
                await this.$axios.put(`quotes/${this.$route.query.quote}/blinds/detach`,  {token: this.$route.query.token})
                    .then(({data}) => {})
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('quotes.error_remove_from_quote')),
                            type: 'error',
                        });
                    });

            await this.$axios.put(`quotes/${this.$route.query.quote}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {
                this.is_adding_item = false;
            }).catch(e => {
                this.is_adding_item = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('quotes.error_add_to_quote')),
                    type: 'error',
                });
            });
        },
        async getCart() {
            this.is_loading_cart = true;

            await this.$axios.get(`carts/${this.$store.getters.cart}`)
                .then(({data}) => {
                    this.cart = data.data;
                    this.is_loading_cart = false;
                })
                .catch(e => {
                    this.is_loading_cart = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_cart')),
                        type: 'error',
                    });
                });
        },
        async addToCart() {
            this.$v.blind.$touch();
            this.$v.custom_colour_code.$touch();

            if (this.$v.blind.$anyError || this.$v.custom_colour_code.$anyError || this.is_adding_item){
                return;
            }

            if (this.motor_selected && this.blind.width < 70) {
                this.$notify({
                    text: 'Width cannot be smaller than 70cm',
                    type: 'error',
                });
                return;
            }

            if(this.blind.width > 250) {
                this.$notify({
                    text: 'Width cannot be larger than 250cm',
                    type: 'error',
                });
                return;
            }

            if(this.blind.width <= 0) {
                this.$notify({
                    text: 'Width cannot be smaller than 1cm',
                    type: 'error',
                });
                return;
            }

            if(this.blind.drop <= 0) {
                this.$notify({
                    text: 'Drop cannot be smaller than 1cm',
                    type: 'error',
                });
                return;
            }

            if(!this.fabric_selected) {
                this.$notify({
                    text: this.$t('calculators.fabric_required'),
                    type: 'error',
                });
                return;
            }

            if(!this.colour_selected) {
                this.$notify({
                    text: this.$t('calculators.fabric_colour_required'),
                    type: 'error',
                });
                return;
            }

            if(!this.cover_option_selected) {
                this.$notify({
                    text: this.$t('calculators.aluminium_cover_required'),
                    type: 'error',
                });
                return;
            }

            if(this.$store.getters.cart)
                await this.getCart();

            let order = await this.getOrder();

            let payload = {
                order: order,
                note: this.note,
                value: this.totalCost,
                options: [
                    {
                        width: this.blind.width,
                        drop: this.blind.drop,
                        recess_type: this.blind.recess_type,
                        width_clearance: this.blind.width_clearance,
                        chain_side: this.blind.chain_side,
                        tile_cut_width: this.blind.tile_cut_width,
                        tile_cut_width_clearance: this.blind.tile_cut_width_clearance,
                        fabric_type: this.fabric_selected.attributes.name,
                        fabric_colour: this.colour_selected,
                        custom_colour_code: this.custom_colour_code,
                        aluminium_cover: this.cover_option_selected.name
                    }
                ],
                is_unavailable: false
            }

            if(this.blind.chain_type)
                payload.options[0].chain_type = this.blind.chain_type.attributes.name;

            if(this.motor_selected)
                payload.options[0].motor = this.motor_selected.attributes.name;

            if(this.controller_types_selected.length)
                payload.options[0].controller_types = this.controller_types_selected;

            this.is_adding_item = true;

            if(this.$route.query.cart && this.$route.query.order) {
                await this.updateCartOrder(payload);

                this.$router.push({path: `/calculators/my-orders/${this.$route.query.order}/order-details`});

                return;
            } else if(this.$route.query.quote) {
                await this.updateQuote(payload);

                if(this.$route.query.order) this.$router.push({path: `/calculators/my-orders/${this.$route.query.order}/order-details`});
                else this.$router.push({path: `/calculators/quotes/${this.$route.query.quote}/quote-details`});

                return;
            }

            if(!this.$store.getters.cart) {
                await this.$store.dispatch('createCart');
            }

            if(this.$route.query.token) {
                this.$axios.put(`carts/${this.$store.getters.cart}/blinds/detach`,  {token: this.$route.query.token})
                    .then(({data}) => {})
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                            type: 'error',
                        });
                    });
            }

            this.$axios.put(`carts/${this.$store.getters.cart}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {
                if(!this.$route.query.token)
                    this.$store.commit('INCREMENT_CART_COUNT');

                if(this.$route.query.token) {
                    this.$router.push({name: 'cart'});
                }

                this.$notify({
                    text: this.$t('cart.item_added_to_cart'),
                    type: 'success',
                });

                this.blind = {
                    width: null,
                    drop: null,
                    recess_type: null,
                    width_clearance: null,
                    chain_side: null,
                    chain_type: null,
                    tile_cut_width: null,
                    tile_cut_width_clearance: null,
                }

                this.fabric_selected = null;
                this.colour_selected = null;
                this.custom_colour_code = null;
                this.cover_option_selected = null;
                this.motor_selected = null;
                this.controller_type_selected = null;
                this.controller_types_selected = [];
                this.note = null;

                this.$v.blind.$reset();
                this.$v.custom_colour_code.$reset();

                this.is_adding_item = false;
            }).catch(e => {
                this.is_adding_item = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
                    type: 'error',
                });
            });
        },
        populate() {
            if(this.$route.query.quote) {
                this.$axios.put(`quotes/${this.$route.query.quote}/blinds`,  {token: this.$route.query.token})
                    .then(({data}) => {
                        const details = JSON.parse(data.options)[0];

                        this.blind.width = details.width;
                        this.blind.drop = details.drop;
                        this.blind.recess_type = details.recess_type;
                        this.blind.width_clearance = details.width_clearance;
                        this.blind.chain_side = details.chain_side;

                        this.chainTypeOptions.forEach(option => {
                            if(option.attributes.name === details.chain_type) {
                                this.blind.chain_type = option;
                            }
                        });

                        this.blind.tile_cut_width = details.tile_cut_width;

                        if(details.tile_cut_width_clearance)
                            this.blind.tile_cut_width_clearance = details.tile_cut_width_clearance;

                        if(data.note)
                            this.note = data.note;

                        this.fabrics.forEach(fabric => {
                            if(fabric.attributes.name === details.fabric_type)
                                this.fabric_selected = fabric;
                        })

                        JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                            if(colour === details.fabric_colour)
                                this.colour_selected = details.fabric_colour;
                        })

                        if(details.custom_colour_code)
                            this.custom_colour_code = details.custom_colour_code;

                        this.aluminium_cover_options.forEach(option => {
                            if(option.name === details.aluminium_cover)
                                this.cover_option_selected = option;
                        })

                        if(details.motor)
                            this.motors.forEach(option => {
                                if(option.attributes.name === details.motor)
                                    this.motor_selected = option;
                            })

                        if(details.controller_type)
                            this.controller_types.forEach(option => {
                                if(option.attributes.name === details.controller_type)
                                    this.controller_type_selected = option;
                            })

                        if(details.controller_types)
                            this.controller_types_selected = details.controller_types;
                    })
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                            type: 'error',
                        });
                    });
            } else if (this.$route.query.cart) {
                this.$axios.put(`carts/${this.$route.query.cart}/blinds`,  {token: this.$route.query.token})
                    .then(({data}) => {
                        const details = JSON.parse(data.options)[0];

                        this.blind.width = details.width;
                        this.blind.drop = details.drop;
                        this.blind.recess_type = details.recess_type;
                        this.blind.width_clearance = details.width_clearance;
                        this.blind.chain_side = details.chain_side;

                        this.chainTypeOptions.forEach(option => {
                            if(option.attributes.name === details.chain_type) {
                                this.blind.chain_type = option;
                            }
                        });

                        this.blind.tile_cut_width = details.tile_cut_width;

                        if(details.tile_cut_width_clearance)
                            this.blind.tile_cut_width_clearance = details.tile_cut_width_clearance;

                        if(data.note)
                            this.note = data.note;

                        this.fabrics.forEach(fabric => {
                            if(fabric.attributes.name === details.fabric_type)
                                this.fabric_selected = fabric;
                        })

                        JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                            if(colour === details.fabric_colour)
                                this.colour_selected = details.fabric_colour;
                        })

                        if(details.custom_colour_code)
                            this.custom_colour_code = details.custom_colour_code;

                        this.aluminium_cover_options.forEach(option => {
                            if(option.name === details.aluminium_cover)
                                this.cover_option_selected = option;
                        })

                        if(details.motor)
                            this.motors.forEach(option => {
                                if(option.attributes.name === details.motor)
                                    this.motor_selected = option;
                            })

                        if(details.controller_type)
                            this.controller_types.forEach(option => {
                                if(option.attributes.name === details.controller_type)
                                    this.controller_type_selected = option;
                            })

                        if(details.controller_types)
                            this.controller_types_selected = details.controller_types;
                    })
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                            type: 'error',
                        });
                    });
            } else {
                this.$axios.put(`carts/${this.$store.getters.cart}/blinds`,  {token: this.$route.query.token})
                    .then(({data}) => {
                        const details = JSON.parse(data.options)[0];

                        this.blind.width = details.width;
                        this.blind.drop = details.drop;
                        this.blind.recess_type = details.recess_type;
                        this.blind.width_clearance = details.width_clearance;
                        this.blind.chain_side = details.chain_side;

                        this.chainTypeOptions.forEach(option => {
                            if(option.attributes.name === details.chain_type) {
                                this.blind.chain_type = option;
                            }
                        });

                        this.blind.tile_cut_width = details.tile_cut_width;

                        if(details.tile_cut_width_clearance)
                            this.blind.tile_cut_width_clearance = details.tile_cut_width_clearance;

                        if(data.note)
                            this.note = data.note;

                        this.fabrics.forEach(fabric => {
                            if(fabric.attributes.name === details.fabric_type)
                                this.fabric_selected = fabric;
                        })

                        JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                            if(colour === details.fabric_colour)
                                this.colour_selected = details.fabric_colour;
                        })

                        if(details.custom_colour_code)
                            this.custom_colour_code = details.custom_colour_code;

                        this.aluminium_cover_options.forEach(option => {
                            if(option.name === details.aluminium_cover)
                                this.cover_option_selected = option;
                        })

                        if(details.motor)
                            this.motors.forEach(option => {
                                if(option.attributes.name === details.motor)
                                    this.motor_selected = option;
                            })

                        if(details.controller_type)
                            this.controller_types.forEach(option => {
                                if(option.attributes.name === details.controller_type)
                                    this.controller_type_selected = option;
                            })

                        if(details.controller_types)
                            this.controller_types_selected = details.controller_types;
                    })
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                            type: 'error',
                        });
                    });
            }
        },
    },
    computed: {
        blindCost() {
            let total = 0;

            this.per_cm_items.forEach(item => {
                if(item.attributes.on_width)
                    total += item.attributes.price * item.attributes.multiplier * this.blind.width;
            })

            this.per_unit_items.forEach(item => {
                if(!item.attributes.is_ignored || (item.attributes.is_ignored && !this.motor_selected))
                    total += item.attributes.price * item.attributes.quantity;
            });

            return total;
        },
        fabricCost() {
            if(this.fabric_selected) {
                let fabric_price = this.fabric_selected.attributes.price;

                if(this.blind.width > this.fabric_selected.attributes.no_wastage_limit) {
                    fabric_price *= (1 + this.fabric_selected.attributes.wastage/100)
                }

                return fabric_price * this.unitsCM2(this.fabric_selected) / 10000;

            } else return 0;
        },
        articlesCost() {
            let total = 0;

            if(this.blind.chain_type)
                total += this.blind.chain_type.attributes.price;

            if(this.motor_selected)
                total += this.motor_selected.attributes.price;

            if(this.controller_type_selected)
                total += this.controller_type_selected.attributes.price;

            if(this.controller_types_selected)
                this.controller_types_selected.forEach(c => {
                    total += c.attributes.price;
                })

            return total;
        },
        coverCost() {
            let total = 0;

            if(this.cover_option_selected && this.cover_option_selected.type === 'with_cover') {
                this.cover_per_cm_items.forEach(item => {
                    total += item.attributes.price * item.attributes.multiplier * this.blind.width;
                });

                this.cover_per_unit_items.forEach(item => {
                    total += item.attributes.price * item.attributes.quantity;
                })
            } else if (this.cover_option_selected && this.cover_option_selected.type === 'without_cover') {
                this.backbar_per_cm_items.forEach(item => {
                    total += item.attributes.price * item.attributes.multiplier * this.blind.width;
                });

                this.backbar_per_unit_items.forEach(item => {
                    total += item.attributes.price * item.attributes.quantity;
                })
            }

            return total;
        },
        totalCost() {
            if(this.fabric_selected)
                return Math.ceil(this.blindCost + this.fabricCost + this.articlesCost + this.coverCost);
            else return 0;
        }
    },
    async mounted() {
        await this.retrieveBlind();
        await this.retrieveFabrics();
        await this.retrieveArticles();

        if(this.$route.query.token) this.populate();
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    @apply px-8 max-w-6xl mx-auto bg-white flex flex-col;

    & > .header-container {
        @apply flex flex-col sticky top-0 bg-white py-8;
        z-index: 99999;

        @screen lg {
            @apply flex-row
        }

        .title-container {
            @apply flex flex-row items-center;

            a {
                @apply text-primary text-2xl cursor-pointer;

                svg {

                    &:hover {
                        animation: spin 0.2s linear;
                        animation-direction: reverse;

                    }
                }
            }

            h1 {
                @apply font-bold text-center text-2xl ml-6;

                @screen lg {
                    @apply text-3xl max-w-full ml-6;
                }
            }
        }

        .price-container {
            @apply flex flex-row items-center mt-4;

            @screen lg{
                @apply ml-auto mx-0 mt-0;
            }

            .price {
                @apply bg-white border-2 border-grey-light rounded py-2 w-5/12 mr-8;

                @screen lg{
                    @apply w-36;
                }

                p {
                    @apply text-center text-black text-xl font-bold truncate;
                }
            }

            button {
                @apply w-7/12 h-12;
            }
        }
    }

    & > .form-container {
        @apply bg-grey-form border-2 border-grey-form rounded-xl mb-8 p-6;

        @screen md {
            @apply p-8;
        }

        @screen lg {
            @apply p-10;
        }

        .form-title {
            @apply text-xl font-bold mb-8;
        }

        h2 {
            @apply text-sm text-black font-bold mb-8;
        }

        .form {
            @apply -mb-4;

            &.main-form {
                @apply mb-2;

                .input-group {
                    .input {
                        width: 50% !important;
                        border-color: theme('colors.primary') !important;
                    }
                }
            }
        }

        .divider {
            @apply mx-auto border-t-2 border-grey-light rounded-md my-8 w-full;

            @screen lg {
                @apply mx-auto;
            }
        }

        .form-wrapper {
            @apply flex flex-col w-full;

            @screen lg {
                @apply flex-row;
            }

            .textboxes-container {
                @apply flex flex-col;

                @screen lg {
                    @apply w-2/5 mr-2;
                }

                @screen xl {
                    @apply w-1/4;
                }

                .input-group {
                    @apply w-full;
                }
            }

            .note-field {
                @apply w-full;

                @screen lg {
                    @apply w-3/5 ml-2;
                }

                @screen xl {
                    @apply w-3/4;
                }
            }
        }

        .custom-colour-field {
            @apply mb-0 mt-8 max-w-sm;
        }
    }

    & > .title {
        @apply font-bold my-4;
    }

    .item-container {
        @apply flex flex-row flex-wrap -m-4;

        .item-card-container {
            @apply w-1/2 p-4;

            @screen md {
                @apply w-1/4;
            }

            @screen lg {
                @apply w-1/5;
            }

            .item-card {
                @apply w-full bg-white rounded-xl flex flex-col h-24 max-w-full px-4 cursor-pointer border-white border-3;
                box-shadow: 0px 10px 15px #00000012;

                &:hover {
                    @apply border-primary;
                }

                &.selected {
                    @apply border-primary;
                }

                &.disabled {
                    @apply cursor-not-allowed bg-grey-light border-grey-light;

                    &:hover {
                        @apply border-grey-light;
                    }
                }

                .item-card-title {
                    @apply text-center m-auto text-sm font-bold;

                    @screen lg {
                        @apply text-base;
                    }
                }

                .price {
                    @apply text-center m-auto text-sm font-bold;

                    @screen lg{
                        @apply text-base;
                    }
                }
            }
        }
    }
}
</style>