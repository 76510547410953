<template>
    <loading-screen v-if="is_loading_order"/>
    <div v-else class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="order" class="reference">{{ order.attributes.reference }}</h1>
            </template>
            <template v-slot:right>
                <div class="status-row">
                    <div class="status-select-container">
                        <p>{{ $t('orders.status') }}</p>
                        <FormInputSelect v-model="order_status" :disabled="is_saving"
                                         :display-custom-label="(row) => `${row.name}`" :options="statusOptions"
                                         :placeholder="$t('orders.status')"
                                         :updated="updatedStatus()" identifier="name"
                                         track-by="name"/>
                    </div>

                    <div class="buttons-container">
                        <Button v-if="$store.getters.hasPermission('update orders')" :class="{spinner: is_printing}"
                                :onclick="generateReport" className="--primary --small">
                            {{ $t('print') }}
                        </Button>

                        <Button v-if="$store.getters.hasPermission('update orders')" :class="{spinner: is_saving}"
                                :onclick="save" className="--primary --small">
                            {{ $t('save') }}
                        </Button>
                    </div>
                </div>

            </template>
        </Headbar>
        <main>
            <div v-if="order" class="form">
                <SectionHeader :title="$t('orders.order_details')"></SectionHeader>
                <div class="form-body">
                    <div class="item-container">
                        <div v-for="item in blinds" class="item-card-container">
                            <item-card
                                :actions_only_on_error="(!$store.getters.hasRole('administrator') && !$store.getters.hasRole('B&D Sales')) && isAgentOrder"
                                :cart_id="isCartOrder ? order.relationships.cart.data.id : null"
                                :dont_show_delete="shouldNotShowEditAndDeleteButtons && blinds.length == 1"
                                :dont_show_edit="shouldNotShowEditAndDeleteButtons"
                                :dont_show_icons="shouldNotShowEditAndDeleteButtons"
                                :item="item"
                                :order_id="order.id" :quote_id="isQuoteOrder ? order.relationships.quote.data.id : null"
                                v-on:click.native="clickCard(item)"></item-card>
                        </div>
                    </div>

                    <div v-if="!isAgentOrder" class="price-breakdown-container">
                        <h1>{{ $t('orders.order_cost_summary') }}</h1>

                        <div class="details-container">
                            <div class="row">
                                <p class="label">{{ $t('cart.sub_total_1') }}</p>
                                <div class="price">€{{ blinds_total.toFixed(2) }}</div>
                            </div>

                            <div v-if="order.attributes.discount" class="row">
                                <p class="label">{{ $t('cart.discount') }} ({{ order.attributes.discount }}%)</p>
                                <div class="price">€{{
                                        (blinds_total * (order.attributes.discount / 100)).toFixed(2)
                                    }}
                                </div>
                            </div>

                            <div v-if="order.attributes.installation" class="row">
                                <p class="label">{{ $t('cart.installation') }}</p>
                                <div class="price">€{{ order.attributes.installation.toFixed(2) }}</div>
                            </div>

                            <div class="row">
                                <p class="label">{{ $t('cart.sub_total_2') }}</p>
                                <div class="price">
                                    €{{
                                        (blinds_total - (order.attributes.discount ? (blinds_total * (order.attributes.discount / 100)).toFixed(2) : 0) + order.attributes.installation).toFixed(2)
                                    }}
                                </div>
                            </div>

                            <div v-if="order.attributes.deposit" class="row">
                                <p class="label">{{ $t('cart.deposit') }}</p>
                                <div class="price">€{{ order.attributes.deposit.toFixed(2) }}</div>
                            </div>

                            <div class="row top-border">
                                <p class="label">{{ $t('cart.balance_inc_vat') }}</p>
                                <div class="price">€{{ order.attributes.value }}</div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="!isAgentOrder && order && order.relationships && order.relationships.customer && order.relationships.customer.data"
                        class="sales-person-container">
                        <h1>{{ $t('cart.sales_person') }}</h1>
                        <Form class="form-container">
                            <FormGroupTwo>
                                <FormInputText v-model="order.relationships.customer.data.attributes.company"
                                               :label="$t('users.company_name')"
                                               :placeholder="$t('users.company_name')" disabled
                                               identifier="company_name"/>
                                <FormInputText v-model="order.relationships.customer.data.attributes.name"
                                               :label="$t('users.name')"
                                               :placeholder="$t('users.name')" disabled identifier="name"/>
                            </FormGroupTwo>
                            <FormInputText v-model="order.relationships.customer.data.attributes.email"
                                           :label="$t('users.email_address')"
                                           :placeholder="$t('users.email_address')" disabled
                                           identifier="email_address"/>
                        </Form>
                    </div>

                    <div
                        v-if="order && order.relationships && order.relationships.customer && order.relationships.customer.data"
                        class="billing-details-container">
                        <h1>{{
                                isAgentOrder ? $t('cart.agent_invoicing_details') : $t('cart.client_invoicing_details')
                            }}</h1>

                        <Form class="form-container">
                            <div class="form-wrapper">
                                <FormGroupTwo>
                                    <FormInputText
                                        v-model="isAgentOrder ? order.relationships.customer.data.attributes.name : order.attributes.client_details.name"
                                        :disabled="isAgentOrder" :label="$t('users.name')"
                                        :placeholder="$t('users.name')"
                                        identifier="name"/>
                                    <FormInputText
                                        v-model="isAgentOrder ? order.relationships.customer.data.attributes.phone : order.attributes.client_details.phone"
                                        :disabled="isAgentOrder" :label="$t('users.contact_number')"
                                        :placeholder="$t('users.contact_number')"
                                        identifier="phone"/>
                                </FormGroupTwo>

                                <FormInputText
                                    v-model="isAgentOrder ? order.relationships.customer.data.attributes.address : order.attributes.client_details.address"
                                    :disabled="isAgentOrder" :label="$t('users.address')"
                                    :placeholder="$t('users.address')"
                                    identifier="address"/>
                                <FormInputText
                                    v-model="isAgentOrder ? order.relationships.customer.data.attributes.email : order.attributes.client_details.email"
                                    :disabled="isAgentOrder" :label="$t('users.email_address')"
                                    :placeholder="$t('users.email_address')"
                                    identifier="email_address"/>

                                <FormGroupTwo v-if="!isAgentOrder">
                                    <FormInputText v-model="order.attributes.discount" :disabled="isAgentOrder"
                                                   :label="`${$t('cart.discount')} (%)`"
                                                   :placeholder="$t('cart.discount')" identifier="discount"
                                                   type="number"/>
                                    <FormInputText v-model="order.attributes.installation" :disabled="isAgentOrder"
                                                   :label="`${$t('users.installation')} (€)`"
                                                   :placeholder="$t('users.installation')"
                                                   identifier="installation" type="number"/>
                                </FormGroupTwo>
                            </div>

                            <div class="form-wrapper">
                                <FormGroupTwo>
                                    <FormInputText
                                        v-model="isAgentOrder ? order.relationships.customer.data.attributes.company : order.attributes.client_details.company"
                                        :disabled="isAgentOrder" :label="$t('users.company_name')"
                                        :placeholder="$t('users.company_name')"
                                        identifier="company_name"/>
                                    <FormInputText
                                        v-model="isAgentOrder ? order.relationships.customer.data.attributes.vat_num : order.attributes.client_details.vat_num"
                                        :disabled="isAgentOrder" :label="$t('users.vat_number')"
                                        :placeholder="$t('users.vat_number')"
                                        identifier="vat_number"/>
                                </FormGroupTwo>

                                <FormInputText v-model="order.attributes.note" :disabled="isAgentOrder"
                                               :label="$t('calculators.note')"
                                               :largeTextarea="true" :placeholder="$t('calculators.note')"
                                               :useTextarea="true"
                                               identifier="note"/>

                                <FormGroupTwo v-if="!isAgentOrder">
                                    <FormInputText v-model="order.attributes.deposit" :disabled="isAgentOrder"
                                                   :label="`${$t('users.deposit')} (€)`"
                                                   :placeholder="$t('users.deposit')" identifier="deposit"
                                                   type="number"/>
                                </FormGroupTwo>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div v-if="order_status && order_status.column === 'on_hold'" class="message-box">
                <SectionHeader :title="$t('orders.message_client')"></SectionHeader>
                <div class="form-body">
                    <FormInputText v-model="$v.message.$model" :disabled="is_saving"
                                   :has-error="$v.message.$error || message_must_be_sent"
                                   :label="$t('orders.message')" :use-textarea="true" identifier="message">
                        <!-- :has-error="$v.user.name.$error" / v-model="$v.user.name.$model" -->
                        <template v-slot:errors>
                            <p v-if="!$v.message.required">
                                {{ $t('orders.message_cant_be_empty') }}
                            </p>
                            <p v-else-if="message_must_be_sent === true">
                                {{ $t('orders.message_must_be_sent') }}
                            </p>
                        </template>
                    </FormInputText>

                    <Button v-if="$store.getters.hasPermission('update orders')" :class="{spinner: is_saving}"
                            :onclick="sendMessage" className="--primary --small --wider">
                        {{ $t('send') }}
                    </Button>

                </div>
            </div>
            <vue-html2pdf
                v-if="order"
                ref="html2Pdf"
                :enable-download="true"
                :filename="`${order.attributes.reference}`"
                :float-layout="true"
                :manual-pagination="true"
                :pdf-quality="2"
                :show-layout="false"
                pdf-content-width="1120px"
                pdf-format="a4"
                pdf-orientation="landscape"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)"
            >
                <section slot="pdf-content" class="pdf-content">
                    <div v-for="(array, index) in printing_pages" class="printing-page">
                        <div class="top-row">
                            <h1 class="reference">{{ order.attributes.reference }} - Blinds Qty {{ blinds.length }} -
                                Page
                                {{ index + 1 }} / {{ printing_pages.length }} </h1>

                            <div v-if="index === 0 && !isAgentOrder" class="sales-person-container">
                                <p><span>SP:</span> {{ order.relationships.customer.data.attributes.name }}</p>
                                <p><span>Company:</span> {{ order.relationships.customer.data.attributes.company }}</p>
                            </div>
                        </div>

                        <div
                            v-if="order && order.relationships && order.relationships.customer && order.relationships.customer.data && index === 0"
                            class="order-details-container">
                            <div class="billing-details-container">
                                <div class="card-title-container">
                                    <h1 class="card-title">
                                        {{
                                            isAgentOrder ? $t('cart.agent_invoicing_details') : $t('cart.client_invoicing_details')
                                        }}</h1>
                                </div>

                                <div class="user-details-wrapper">
                                    <div class="user-details-container">
                                        <div class="user-detail">
                                            <p class="label">{{ $t('users.date') }}:</p>
                                            <p>{{
                                                    $moment.utc(order.attributes.created_at).local().format('DD/MM/YYYY - HH:mm')
                                                }}</p>
                                        </div>

                                        <div class="user-detail">
                                            <p class="label">{{ $t('users.name') }}:</p>
                                            <p>{{
                                                    isAgentOrder ? order.relationships.customer.data.attributes.name : order.attributes.client_details.name
                                                }}</p>
                                        </div>

                                        <div class="user-detail">
                                            <p class="label">{{ $t('users.contact') }}:</p>
                                            <p>{{
                                                    isAgentOrder ? order.relationships.customer.data.attributes.phone : order.attributes.client_details.phone
                                                }}</p>
                                        </div>
                                    </div>

                                    <div class="user-detail">
                                        <p class="label">{{ $t('users.address') }}:</p>
                                        <p>{{
                                                isAgentOrder ? order.relationships.customer.data.attributes.address : order.attributes.client_details.address
                                            }}</p>
                                    </div>

                                    <div class="user-detail">
                                        <p class="label">{{ $t('users.email_address') }}:</p>
                                        <p>{{
                                                isAgentOrder ? order.relationships.customer.data.attributes.email : order.attributes.client_details.email
                                            }}</p>
                                    </div>

                                    <div
                                        v-if="(isAgentOrder && order.relationships.customer.data.attributes.company || order.relationships.customer.data.attributes.vat_num) || (!isAgentOrder && order.attributes.client_details.company || order.attributes.client_details.vat_num)"
                                        class="user-details-container">
                                        <div
                                            v-if="(isAgentOrder && order.relationships.customer.data.attributes.company) || (!isAgentOrder && order.attributes.client_details.company)"
                                            class="user-detail">
                                            <p class="label">{{ $t('users.company_name') }}:</p>
                                            <p>{{
                                                    isAgentOrder ? order.relationships.customer.data.attributes.company : order.attributes.client_details.company
                                                }}</p>
                                        </div>

                                        <div
                                            v-if="(isAgentOrder && order.relationships.customer.data.attributes.vat_num) || (!isAgentOrder && order.attributes.client_details.vat_num)"
                                            class="user-detail">
                                            <p class="label">{{ $t('users.vat_number') }}:</p>
                                            <p>{{
                                                    isAgentOrder ? order.relationships.customer.data.attributes.vat_num : order.attributes.client_details.vat_num
                                                }}</p>
                                        </div>
                                    </div>

                                    <div v-if="order.attributes.note" class="user-detail">
                                        <p class="label">{{ $t('cart.note') }}:</p>
                                        <p v-if="order.attributes.note">{{ order.attributes.note }}</p>
                                    </div>
                                </div>
                            </div>

                            <div v-if="!isAgentOrder" class="price-breakdown-container">
                                <div class="card-title-container">
                                    <h1 class="card-title">{{ $t('cart.order_cost_summary') }}</h1>
                                </div>

                                <div class="details-container">
                                    <div class="row">
                                        <p class="label">{{ $t('cart.sub_total_1') }}</p>
                                        <div class="price">€{{ blinds_total.toFixed(2) }}</div>
                                    </div>

                                    <div v-if="order.attributes.discount" class="row">
                                        <p class="label">{{ $t('cart.discount') }} ({{
                                                order.attributes.discount
                                            }}%)</p>
                                        <div class="price">
                                            €{{ (blinds_total * (order.attributes.discount / 100)).toFixed(2) }}
                                        </div>
                                    </div>

                                    <div v-if="order.attributes.installation" class="row">
                                        <p class="label">{{ $t('cart.installation') }}</p>
                                        <div class="price">€{{ order.attributes.installation.toFixed(2) }}</div>
                                    </div>

                                    <div class="row">
                                        <p class="label">{{ $t('cart.sub_total_2') }}</p>
                                        <div class="price">
                                            €{{
                                                (blinds_total - (order.attributes.discount ? (blinds_total * (order.attributes.discount / 100)).toFixed(2) : 0) + order.attributes.installation).toFixed(2)
                                            }}
                                        </div>
                                    </div>

                                    <div v-if="order.attributes.deposit" class="row bottom-border">
                                        <p class="label">{{ $t('cart.deposit') }}</p>
                                        <div class="price">€{{ order.attributes.deposit.toFixed(2) }}</div>
                                    </div>

                                    <div class="row">
                                        <p class="label">{{ $t('cart.balance_inc_vat') }}</p>
                                        <div class="price">€{{ order.attributes.value }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item-cards-container">
                            <div v-for="item in array" class="item-card-container">
                                <item-card-printing :item="item" class="item-card-wrapper"/>
                            </div>
                        </div>

                        <div v-if="index !== printing_pages.length-1" class="html2pdf__page-break"/>
                    </div>
                </section>
            </vue-html2pdf>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormInputPassword from "../../components/form/FormInputPassword";
import ItemCard from "@/components/calculators/ItemCard";
import VueHtml2pdf from 'vue-html2pdf'
import ItemCardPrinting from "@/components/calculators/ItemCardPrinting";
import LoadingScreen from "@/components/LoadingScreen";

export default {
    name: "users-update-page",
    components: {
        LoadingScreen,
        ItemCardPrinting,
        ItemCard,
        FormGroupTwo,
        FormInputText,
        FormInputPassword,
        FormInputSelect,
        Button,
        SectionHeader,
        Form,
        Headbar,
        VueHtml2pdf
    },
    data() {
        return {
            order: null,
            order_status: null,
            message: null,
            blinds: [],
            blinds_total: 0,
            statusOptions: [],
            chosenCards: [],
            is_saving: false,
            is_sending: false,
            is_printing: false,
            is_loading_order: false,
            is_loading_statuses: false,
            is_message_sent: false,
            message_must_be_sent: false,
            printing_pages: []
        }
    },
    validations: {
        message: {
            required,
        }
    },
    computed: {
        isAgentOrder() {
            return !this.order.attributes.client_details;
        },
        isCartOrder() {
            return this.order.relationships.cart_blinds.data.length > 0;
        },
        isQuoteOrder() {
            return this.order.relationships.quote_blinds.data.length > 0;
        },
        shouldNotShowEditAndDeleteButtons() {
            if (!this.$store.getters.hasRole('administrator') && !this.$store.getters.hasRole('B&D Sales')) return true
            if (!this.$store.getters.hasRole('administrator') && this.isAgentOrder) return true
            if (this.$store.getters.hasRole('B&D Sales') && this.order.attributes.status !== 'processing' && this.order.attributes.status !== 'on_hold' && this.order.attributes.status !== 'measuring') return true
        },
    },
    methods: {
        clickCard(cardDetails) {
            let Iterator   = 0;
            let matchFound = false;

            if (this.order_status.column.trim() !== 'on_hold') return;

            this.chosenCards.forEach(card => {
                if (card.attributes.token === cardDetails.attributes.token) matchFound = true;
                if (!matchFound) Iterator++;
            })

            if (matchFound) this.chosenCards.splice(Iterator, 1)
            else this.chosenCards.push(cardDetails)
            cardDetails.attributes.is_unavailable = !cardDetails.attributes.is_unavailable
        },
        updatedStatus() {
            if (!this.order_status || !this.order_status.column) return
            if (this.order_status.column.trim() === 'on_hold') return;

            this.blinds.forEach(blind => {
                blind.attributes.is_unavailable = false;
            })
        },
        async updateOrderValue() {
            await this.$axios.patch(`orders/${this.$route.params.id}`, {value: (this.blinds_total - (this.order.attributes.discount ? (this.blinds_total * (this.order.attributes.discount / 100)).toFixed(2) : 0) + (this.order.attributes.installation ? parseInt(this.order.attributes.installation) : 0) - (this.order.attributes.deposit ? parseInt(this.order.attributes.deposit) : 0)).toFixed(2)})
                .then(({data}) => {
                    this.order.attributes.value = data.data.attributes.value;
                    this.is_loading_order       = false;
                })
                .catch(e => {
                    this.is_loading_order = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_order')),
                        type: 'error',
                    });
                });
        },
        async getOrder() {
            this.is_loading_order = true;
            this.blinds_total     = 0;

            await this.$axios.get(`orders/${this.$route.params.id}`)
                .then(({data}) => {
                    this.order        = data.data;
                    this.order_status = _.find(this.statusOptions, {column: this.order.attributes.status});

                    if (data.data.relationships.cart_blinds.data.length > 0) {
                        this.blinds = data.data.relationships.cart_blinds.data;
                    } else if (data.data.relationships.quote_blinds.data.length > 0) {
                        this.blinds = data.data.relationships.quote_blinds.data;
                    } else if (data.data.relationships.cart_blinds.data.length === 0 && data.data.relationships.quote_blinds.data.length === 0) {
                        this.blinds = []
                    }

                    this.blinds.sort((a, b) => a.attributes.order - b.attributes.order);

                    if (this.blinds.length) {
                        this.blinds.forEach(blind => {
                            blind.attributes.options = JSON.parse(blind.attributes.options);
                            this.blinds_total += blind.attributes.value;
                        })
                    }

                    this.printing_pages            = [];
                    let temp_blinds_printing_array = _.clone(this.blinds);

                    for (let i = 0; i < this.blinds.length / 3; i++) {
                        if (i === 0 && temp_blinds_printing_array.slice(0, 3).find(blind => blind.attributes.type === 'curtain_blind')) {
                            this.printing_pages.push([]);
                        }

                        this.printing_pages.push(temp_blinds_printing_array.splice(0, 3));
                    }

                    this.updateOrderValue();

                    this.is_loading_order = false;
                })
                .catch(e => {
                    this.is_loading_order = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('orders.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async getStatuses() {
            this.is_loading_statuses = true;

            await this.$axios.get(`orders/list-statuses`)
                .then(({data}) => {
                    this.statusOptions       = data;
                    this.is_loading_statuses = false;
                })
                .catch(e => {
                    this.is_loading_order = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('orders.error_retrieve_statuses')),
                        type: 'error',
                    });
                });
        },
        async generateReport() {
            this.is_printing = true;
            await this.$refs.html2Pdf.generatePdf()
        },
        async beforeDownload() {
            this.is_printing = true;
        },
        async hasDownloaded() {
            this.is_printing = false;
        },
        async save() {
            this.is_saving            = true;
            this.message_must_be_sent = false;
            let listOfBlinds          = {tokens: []}

            let value = this.blinds_total - (this.order.attributes.discount ? (this.blinds_total * (this.order.attributes.discount / 100)).toFixed(2) : 0) + (this.order.attributes.installation ? parseInt(this.order.attributes.installation) : 0) - (this.order.attributes.deposit ? parseInt(this.order.attributes.deposit) : 0).toFixed(2);

            if (value < 0) {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(this.$t('quotes.error_value_negative')),
                    type: 'error',
                });

                this.is_saving = false;

                return;
            }

            if (this.order_status.column.trim() === 'on_hold') {
                let unavailableCount = 0;

                if (this.is_message_sent === false) {
                    this.$v.message.$touch();
                    if (this.$v.message.$anyError || this.is_processing) {
                        this.is_saving = false;
                        return;
                    }

                    this.message_must_be_sent = true;
                    this.is_saving            = false;
                    return;
                }

                this.blinds.forEach(blind => {
                    listOfBlinds.tokens.push({token: blind.attributes.token, value: !!blind.attributes.is_unavailable})
                    if (blind.attributes.is_unavailable) unavailableCount += 1;
                })

                if (unavailableCount === 0) {
                    this.is_saving = false;
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$t('orders.must_select_item_for_on_hold'),
                        type: 'error'
                    });
                    return;
                } else {
                    await this.changeBlindAvailability(listOfBlinds)
                }

            } else {
                this.blinds.forEach(blind => {
                    listOfBlinds.tokens.push({token: blind.attributes.token, value: false})
                })

                await this.changeBlindAvailability(listOfBlinds)
            }

            let payload = {status: this.order_status.column.trim()}

            if (!this.isAgentOrder) {
                payload = {
                    ...payload,
                    client_details: this.order.attributes.client_details,
                    installation: parseInt(this.order.attributes.installation),
                    deposit: parseInt(this.order.attributes.deposit),
                    discount: parseInt(this.order.attributes.discount),
                    note: this.order.attributes.note,
                };
            }

            await this.$axios.patch(`orders/${this.order.id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('orders.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.getOrder();
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('users.error_update')),
                    type: 'error',
                });
            });
        },
        async changeBlindAvailability(blinds) {
            if (this.isCartOrder) {
                await this.$axios.post(`orders/${this.order.id}/set-unavailable`, blinds).then(({data}) => {
                    this.$notify({
                        text: this.$t('orders.blind_availability_updated'),
                        type: 'success',
                    });
                }).catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('users.error_update')),
                        type: 'error',
                    });
                });
            } else if (this.isQuoteOrder) {
                await this.$axios.post(`orders/${this.order.id}/set-unavailable-quote`, blinds).then(({data}) => {
                    this.$notify({
                        text: this.$t('orders.blind_availability_updated'),
                        type: 'success',
                    });
                }).catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('users.error_update')),
                        type: 'error',
                    });
                });
            }

        },
        sendMessage() {
            this.$v.message.$touch();
            if (this.$v.message.$anyError || this.is_sending) return;
            let payload = {message: this.message}

            this.$axios.post(`orders/${this.order.id}/send-message`, payload).then(({data}) => {
                this.is_message_sent = true;
                this.$notify({
                    text: this.$t('orders.success_message_sent'),
                    type: 'success',
                });
                this.message = null;
                this.$v.$reset();
                this.is_sending = false;
            }).catch(e => {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('orders.error_message_sent')),
                    type: 'error',
                });
                this.message = null;
                this.$v.$reset();
                this.is_sending = false;
            });
        }
    },
    async mounted() {
        await this.getStatuses();
        await this.getOrder();
    },
    head() {
        return {
            title: {
                inner: this.$t('orders.update_order')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
@media print {
    @page {
        size: landscape
    }
}

.pdf-content {
    @apply px-8 py-2;

    .printing-page {
        @apply pt-4;

        &:first-of-type {
            @apply pt-0;
        }
    }

    .top-row {
        @apply flex flex-row justify-between mb-4;

        .reference {
            @apply text-black font-bold text-2xl my-auto -mt-1;
        }

        .sales-person-container {
            @apply flex flex-row border-1 border-black rounded-md py-4 px-2 bg-grey-light mt-1;

            p {
                @apply text-sm px-2 -mt-3;

                &.title {
                    @apply font-bold;
                }

                span {
                    @apply font-bold;
                }
            }
        }
    }

    .item-cards-container {
        @apply flex flex-row -mx-2;

        .item-card-container {
            @apply w-1/3 px-2 mt-2;
        }
    }

    .order-details-container {
        @apply flex flex-row mb-2;

        .billing-details-container {
            @apply flex flex-col rounded-md border-1 border-black bg-grey-light mr-2;
            width: 60%;

            .card-title-container {
                @apply px-4 py-1 bg-black text-white;

                .card-title {
                    @apply font-bold text-sm -mt-2 mb-2;
                }
            }

            .user-details-wrapper {
                @apply px-4 py-2;

                .user-details-container {
                    @apply flex flex-row;
                }

                .user-detail {
                    @apply flex flex-row mr-8;

                    p {
                        &:nth-child(even) {
                            @apply ml-1;
                        }
                    }

                    &:last-child {
                        @apply mr-0;
                    }

                    .label {
                        @apply font-bold mb-2 text-xs;
                    }

                    p {
                        @apply mb-2 text-xs;
                    }
                }
            }

            & > h1 {
                @apply font-bold text-lg mb-4;
            }
        }

        .price-breakdown-container {
            @apply ml-2 rounded-md border-1 border-black bg-grey-light;
            width: 40%;

            .card-title-container {
                @apply px-4 py-1 bg-black text-white;

                .card-title {
                    @apply font-bold text-sm -mt-2 mb-2;
                }
            }

            .details-container {
                @apply flex flex-col flex-wrap max-h-40 px-4 py-2 -mx-4;

                .row {
                    @apply flex flex-row text-xs justify-between mx-4 mb-4;

                    .label {
                        @apply font-bold mr-4;
                    }

                    &.bottom-border {
                        @apply pb-4 mb-0 border-b border-black;
                    }
                }
            }
        }
    }
}

.page-container {

    .status-row {
        @apply flex flex-col w-full mt-4;

        @screen sm {
            @apply flex-row;
        }

        @screen lg {
            @apply mt-0;
        }

        .status-select-container {
            @apply flex flex-row items-center w-full mx-auto mb-4;

            @screen sm {
                @apply my-auto;
            }

            p {
                @apply mr-4 text-xs;
            }

            .input-group {
                @apply mb-0 w-40 mr-4;
            }
        }

        .buttons-container {
            @apply flex flex-row mx-auto;

            @screen sm {
                @apply my-auto;
            }

            button {
                @apply mr-4;
            }
        }
    }

    main {
        @apply flex flex-row flex-wrap;

        .message-box {
            @apply mb-8 bg-white h-full w-full;

            @screen sm {
                width: calc(30% - 1rem);
            }

            .form-body {
                @apply py-5 px-8;

                .input-group {

                    ::v-deep textarea {
                        @apply h-48;
                    }
                }
            }
        }

        .form {
            @apply mb-8 bg-white w-full;

            @screen lg {
                width: calc(70% - 1rem);
            }

            &:nth-child(odd) {
                @apply mr-4;
            }

            &:nth-child(even) {
                @apply ml-4;
            }

            .form-body {
                @apply px-4 py-7;

                &.next-of-kin {
                    .next-of-kin-row-group {
                        .input-group {
                            @apply w-auto flex-1;
                        }

                        .button-remove {
                            @apply flex-none self-start mt-9;
                        }
                    }
                }

                & > .item-container {
                    @apply flex flex-row flex-wrap w-full px-3;

                    .item-card-container {
                        @apply w-full py-5 px-4;

                        @screen xl {
                            @apply w-1/2 p-3;
                        }

                        & > .item-card {
                            box-shadow: 0px 0px 15px #00000012;
                        }
                    }
                }

                & > .price-breakdown-container {
                    @apply flex flex-col rounded-xl bg-grey-form p-8 pb-6 mx-6 mt-4;

                    @screen xl {
                        @apply mt-8;
                    }

                    & > h1 {
                        @apply font-bold text-xl mb-6;
                    }

                    .details-container {
                        @apply flex flex-col py-2;

                        @screen md {
                            @apply px-4;
                        }

                        .row {
                            @apply flex flex-row text-sm mb-4 w-full justify-between;

                            @screen md {
                                @apply w-56;
                            }

                            .label {
                                @apply font-bold;
                            }

                            &.top-border {
                                @apply border-t-2 border-primary pt-3;
                            }
                        }
                    }
                }

                & > .sales-person-container {
                    @apply flex flex-col rounded-xl bg-grey-form p-8 pb-6 mx-6 mt-4;

                    @screen xl {
                        @apply mt-8;
                    }

                    & > h1 {
                        @apply font-bold text-xl mb-6;
                    }

                    & > .form-container {
                        @apply flex flex-col bg-grey-form;

                        @screen lg {
                            @apply flex flex-row;
                        }

                        & > .input-group-two {
                            @screen lg {
                                @apply w-1/2 mr-1;
                            }
                        }

                        & > .input-group {
                            @screen lg {
                                @apply w-1/2 ml-1;
                            }
                        }

                        .form-wrapper {
                            @screen lg {
                                @apply w-1/2 mx-2;
                            }
                        }
                    }
                }

                & > .billing-details-container {
                    @apply flex flex-col rounded-xl bg-grey-form p-8 pb-6 mx-6 mt-4;

                    @screen xl {
                        @apply mt-8;
                    }

                    & > h1 {
                        @apply font-bold text-xl mb-6;
                    }

                    & > .form-container {
                        @apply flex flex-col -mx-2 bg-grey-form;

                        @screen xl {
                            @apply flex flex-row;
                        }

                        .form-wrapper {
                            @apply w-full;

                            @screen xl {
                                @apply w-1/2 mx-2;
                            }
                        }
                    }
                }
            }

            .row-add-container {
                @apply px-8 pb-8 mt-auto;
            }
        }
    }
}
</style>