<template>
    <loading-screen v-if="is_loading_blind || is_loading_articles || is_loading_fabrics"></loading-screen>
    <div v-else class="page-container">
        <div class="header-container" v-if="blind_details">
            <div class="title-container">
                <router-link :to="{name: 'calculators-index'}">
                    <font-awesome-icon :icon="['far', 'arrow-circle-left']"/>
                </router-link>
                <h1>{{blind_details.attributes.name}}</h1>
            </div>
            <div class="price-container">
                <div class="price">
                    <p>€{{totalCost}}</p>
                </div>
                <Button type="submit" className="--primary --small" :onclick="addToCart" :class="{'spinner-black': is_adding_item}">
                    {{$route.query.token ? $t('calculators.update') : ($route.query.quote ? $t('calculators.add_to_quote') : $t('calculators.add_to_cart'))}}
                </Button>
            </div>
        </div>
        <div class="form-container">
            <h1 class="form-title">{{$t('calculators.measurements')}}</h1>

            <FormGroupFour class="form main-form">
                <FormInputText v-model="$v.width.$model" type="number" identifier="width" :label="$t('calculators.internal_width') + ' (cm)'" :tippyText="$t('calculators.pvc_internal_width_info')"
                               :placeholder="$t('calculators.internal_width')" borderColor="primary" :has-error="$v.width.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.width.required">
                            {{$t('validation.x_is_required', {x: $t('calculators.internal_width')})}}
                        </p>
                    </template>
                </FormInputText>
                <FormInputSelect v-model="$v.blind_type_selected.$model" type="number" identifier="blind-type" :label="$t('calculators.blind_type')"
                                 :placeholder="$t('calculators.blind_type')" :options="blindTypeOptions" :has-error="$v.blind_type_selected.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.blind_type_selected.required">
                            {{$t('validation.x_is_required', {x: $t('calculators.blind_type')})}}
                        </p>
                    </template>
                </FormInputSelect>
            </FormGroupFour>

            <div class="divider"></div>

            <div class="form-wrapper form">
                <FormInputText class="note-field" v-model="note" identifier="note" :label="$t('calculators.note')" :placeholder="$t('calculators.note')" :useTextarea="true" :largeTextarea="true"></FormInputText>
            </div>
        </div>
        <div class="form-container cards">
            <h1 class="form-title">{{$t('calculators.finish')}}</h1>
            <div class="item-container">
                <div class="item-card-container" v-for="finish_style in finishStyleOptions">
                    <div class="item-card" @click="onFinishStyleSelect(finish_style)" :class="{'selected': finish_style_selected === finish_style}">
                        <h1 class="item-card-title">{{finish_style.attributes.name}}</h1>
                    </div>
                </div>
            </div>

            <div class="divider"></div>

            <div class="item-container">
                <div class="item-card-container" v-for="finish in finishColourOptions">
                    <div class="item-card" @click="onFinishSelect(finish)" :class="{'selected': finish_colour_selected === finish}">
                        <h1 class="item-card-title">{{finish.attributes.name}}</h1>
                    </div>
                </div>
            </div>

            <FormInputText class="custom-colour-field" v-show="finish_colour_selected && finish_colour_selected.attributes.name === 'Custom Colour'"
                           v-model="$v.custom_colour_code.$model" type="text" identifier="custom-colour-code" :label="$t('calculators.custom_colour_code')"
                           :placeholder="$t('calculators.custom_colour_code')" :has-error="$v.custom_colour_code.$error">
                <template v-slot:errors>
                    <p v-if="!$v.custom_colour_code.required">
                        {{$t('validation.x_is_required', {x: $t('calculators.custom_colour_code')})}}
                    </p>
                </template>
            </FormInputText>
        </div>

        <div class="form-container cards" v-if="motors.length || controller_types.length">
            <h1 class="form-title">Home Automation</h1>
            <div class="category-container">
                <h2>Motors</h2>

                <div class="item-container">
                    <div class="item-card-container" v-for="motor in motors">
                        <div class="item-card" @click="onMotorSelected(motor)" :class="{'selected': motor === motor_selected, 'disabled': motor.disabled}">
                            <h1 class="item-card-title">{{motor.attributes.name}}</h1>
                        </div>
                    </div>
                </div>

                <div v-if="controller_types.length > 0" class="divider"></div>

                <h2>Controller Type</h2>

                <div class="item-container">
                    <div class="item-card-container" v-for="controller_type in controller_types">
                        <div class="item-card" @click="onControllerTypeSelected(controller_type)" :class="{'selected': controller_type === controller_type_selected}">
                            <h1 class="item-card-title">{{controller_type.attributes.name}}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button";
import FormGroupFour from "@/components/form/FormGroupFour";
import FormInputText from "@/components/form/FormInputText";
import FormGroupTwo from "@/components/form/FormGroupTwo";
import FormInputSelect from "@/components/form/FormInputSelect";
import LoadingScreen from "@/components/LoadingScreen";
import {minValue, required, requiredIf} from "vuelidate/lib/validators";

export default {
    name: "PVCCustomCover",
    components: {LoadingScreen, FormInputSelect, FormGroupTwo, FormInputText, FormGroupFour, Button},
    data() {
        return {
            width: null,
            note: null,
            blind_type_selected: null,
            finish_style_selected: null,
            finish_colour_selected: null,
            custom_colour_code: null,

            articles: [],
            motors: [],
            controller_types: [],
            motor_selected: null,
            controller_type_selected: null,

            blindTypeOptions: [
                'Zebra Blind',
                'Roller Blind 20 - 183cm',
                'Roller Blind 184 - 240cm',
                'Roller Blind 241 - 300cm',
                'Curtain Blind',
                'Vertical Blind - 90mm Slat',
                'Vertical Blind - 127mm Slat',
                'Venetian Blind 25mm',

            ],
            finishStyleOptions: [],
            finishColourOptions: [],
            blind_details: null,
            is_loading_blind: true,
            is_loading_articles: true,
            is_loading_fabrics: true,
            is_adding_item: false
        }
    },
    validations: {
        width: {
            required,
        },
        blind_type_selected: {required},
        custom_colour_code: {
            required: requiredIf(function () {
                return this.finish_colour_selected &&  this.finish_colour_selected.attributes.name === 'Custom Colour';
            })
        },
    },
    computed: {
        articlesCost() {
            let total = 0;

            if(this.motor_selected)
                total += this.motor_selected.attributes.price;

            if(this.controller_type_selected)
                total += this.controller_type_selected.attributes.price;

            return total;
        },
        totalCost() {
            let total = 0;

            if(this.width) {
                if(this.finish_style_selected)
                    total += this.finish_style_selected.attributes.price * this.finish_style_selected.attributes.multiplier * this.width;

                if(this.finish_colour_selected)
                    total += this.finish_colour_selected.attributes.price * this.finish_colour_selected.attributes.multiplier * this.width;
            }

            total += this.articlesCost;

            return Math.ceil(total);
        }
    },
    methods: {
        onFinishStyleSelect(finish_style) {
            this.finish_style_selected = finish_style;
        },
        onFinishSelect(finish) {
            this.finish_colour_selected = finish;
        },
        onMotorSelected(motor) {
            if(motor === this.motor_selected)
                this.motor_selected = null;
            else
                this.motor_selected = motor;
        },
        onControllerTypeSelected(controller_type) {
            if(controller_type === this.controller_type_selected)
                this.controller_type_selected = null;
            else
                this.controller_type_selected = controller_type;
        },
        async retrieveBlind() {
            this.is_loading_blind = true;

            await this.$axios.get(`blinds/${this.$route.query.id}`).then(({data}) => {
                this.blind_details = data.data;
                this.is_loading_blind = false;
            }).catch(e => {
                this.is_loading_blind = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_blind')),
                    type: 'error',
                });
            });
        },
        async retrieveFabrics() {
            this.is_loading_fabrics = true;

            await this.$axios.get(`blinds/${this.$route.query.id}/fabrics`).then(({data}) => {
                this.fabrics = data.data.sort((a,b) => a.attributes.order - b.attributes.order);

                this.fabrics.forEach(fabric => {
                    if(fabric.relationships.category.data && fabric.relationships.category.data.attributes && fabric.relationships.category.data.attributes.name === 'PVC Custom Cover Finish Style')
                        this.finishStyleOptions.push(fabric);

                    if(fabric.relationships.category.data && fabric.relationships.category.data.attributes && fabric.relationships.category.data.attributes.name === 'PVC Custom Cover Finish Colour')
                        this.finishColourOptions.push(fabric);
                });

                this.is_loading_fabrics = false;
            }).catch(e => {
                this.is_loading_fabrics = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_fabrics')),
                    type: 'error',
                });
            });
        },
        async retrieveArticles() {
            this.is_loading_articles = true;

            await this.$axios.get(`blinds/${this.$route.query.id}/articles/list`).then(({data}) => {
                this.articles = data.data;

                this.articles.forEach(article => {
                    if(article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Motors')
                        this.motors.push(article);

                    if(article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Controller Types')
                        this.controller_types.push(article);
                });

                this.is_loading_articles = false;
            }).catch(e => {
                this.is_loading_articles = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_articles')),
                    type: 'error',
                });
            });
        },
        async getOrder() {
            let order = 0;

            if(!this.$route.query.token) {
                if(this.$route.query.quote) {
                    let order = 0;

                    await this.$axios.get(`/quotes/${this.$route.query.quote}`).then(({data}) => {
                        if(data.data.relationships.blinds.data.length)
                            data.data.relationships.blinds.data.forEach(blind => {
                                if(blind.attributes.order > order)
                                    order = blind.attributes.order;
                            })
                    });

                    return order += 1;
                } else if(this.cart) {
                    this.cart.relationships.blinds.data.forEach(blind => {
                        if(blind.attributes.order > order)
                            order = blind.attributes.order;
                    })
                }

                return order += 1;
            } else {
                return order = this.$route.query.cart_order ? this.$route.query.cart_order : 0;
            }
        },
        async updateCartOrder(payload) {
            await this.$axios.put(`carts/${this.$route.query.cart}/blinds/detach`,  {token: this.$route.query.token})
                .then(({data}) => {})
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                        type: 'error',
                    });
                });

            await this.$axios.put(`carts/${this.$route.query.cart}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {
                this.is_adding_item = false;
            }).catch(e => {
                this.is_adding_item = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
                    type: 'error',
                });
            });
        },
        async updateQuote(payload) {
            this.is_adding_item = false;

            if(this.$route.query.token)
                await this.$axios.put(`quotes/${this.$route.query.quote}/blinds/detach`,  {token: this.$route.query.token})
                    .then(({data}) => {})
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('quotes.error_remove_from_quote')),
                            type: 'error',
                        });
                    });

            await this.$axios.put(`quotes/${this.$route.query.quote}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {
                this.is_adding_item = false;
            }).catch(e => {
                this.is_adding_item = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('quotes.error_add_to_quote')),
                    type: 'error',
                });
            });
        },
        async getCart() {
            this.is_loading_cart = true;

            await this.$axios.get(`carts/${this.$store.getters.cart}`)
                .then(({data}) => {
                    this.cart = data.data;
                    this.is_loading_cart = false;
                })
                .catch(e => {
                    this.is_loading_cart = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_cart')),
                        type: 'error',
                    });
                });
        },
        async addToCart() {
            this.$v.width.$touch();
            this.$v.blind_type_selected.$touch();
            this.$v.custom_colour_code.$touch();

            if (this.$v.width.$anyError || this.$v.blind_type_selected.$anyError || this.$v.custom_colour_code.$anyError || this.is_adding_item)
                return;

            if(this.width > 500) {
                this.$notify({
                    text: 'Width cannot be larger than 500cm',
                    type: 'error',
                });
                return;
            }

            if(this.width <= 0) {
                this.$notify({
                    text: 'Width cannot be smaller than 1cm',
                    type: 'error',
                });
                return;
            }

            if(!this.finish_style_selected || !this.finish_colour_selected) {
                this.$notify({
                    text: this.$t('calculators.finish_style_required'),
                    type: 'error',
                });
                return;
            }

            if(this.$store.getters.cart)
                await this.getCart();

            let order = await this.getOrder();

            let payload = {
                order: order,
                note: this.note,
                value: this.totalCost,
                options: [
                    {
                        width: this.width,
                        blind_type: this.blind_type_selected,
                        finish_style: this.finish_style_selected.attributes.name,
                        finish: this.finish_colour_selected.attributes.name,
                        custom_colour_code: this.custom_colour_code
                    }
                ],
                is_unavailable: false
            }

            if(this.motor_selected)
                payload.options[0].motor = this.motor_selected.attributes.name;

            if(this.controller_type_selected)
                payload.options[0].controller_type = this.controller_type_selected.attributes.name;

            this.is_adding_item = true;

            if(this.$route.query.cart && this.$route.query.order) {
                await this.updateCartOrder(payload);

                this.$router.push({path: `/calculators/my-orders/${this.$route.query.order}/order-details`});

                return;
            }

            if(this.$route.query.quote) {
                await this.updateQuote(payload);

                if(this.$route.query.order) this.$router.push({path: `/calculators/my-orders/${this.$route.query.order}/order-details`});
                else this.$router.push({path: `/calculators/quotes/${this.$route.query.quote}/quote-details`});

                return;
            }

            if(!this.$store.getters.cart) {
                await this.$store.dispatch('createCart');
            }

            if(this.$route.query.token) {
                this.$axios.put(`carts/${this.$store.getters.cart}/blinds/detach`,  {token: this.$route.query.token})
                    .then(({data}) => {})
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                            type: 'error',
                        });
                    });
            }

            this.$axios.put(`carts/${this.$store.getters.cart}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {

                if(!this.$route.query.token)
                    this.$store.commit('INCREMENT_CART_COUNT');

                if(this.$route.query.token) {
                    this.$router.push({name: 'cart'});
                }

                this.$notify({
                    text: this.$t('cart.item_added_to_cart'),
                    type: 'success',
                });

                this.width = null;
                this.blind_type_selected = null;
                this.finish_style_selected = null;
                this.finish_colour_selected = null;
                this.custom_colour_code = null;
                this.motor_selected = null;
                this.controller_type_selected = null;
                this.note = null;

                this.$v.width.$reset();
                this.$v.blind_type_selected.$reset();
                this.$v.custom_colour_code.$reset();

                this.is_adding_item = false;
            }).catch(e => {
                this.is_adding_item = false;

                this.$notify({
                    text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
                    type: 'error',
                });
            });
        },
        populate() {
            if(this.$route.query.quote) {
                this.$axios.put(`quotes/${this.$route.query.quote}/blinds`,  {token: this.$route.query.token})
                    .then(({data}) => {
                        const details = JSON.parse(data.options)[0];

                        this.width = details.width;
                        this.blind_type_selected = details.blind_type;

                        if(data.note)
                            this.note = data.note;

                        this.finishStyleOptions.forEach(option => {
                            if(option.attributes.name === details.finish_style)
                                this.finish_style_selected = option;
                        })

                        this.finishColourOptions.forEach(option => {
                            if(option.attributes.name === details.finish)
                                this.finish_colour_selected = option;
                        })

                        if(details.custom_colour_code)
                            this.custom_colour_code = details.custom_colour_code;

                        if(details.motor)
                            this.motors.forEach(option => {
                                if(option.attributes.name === details.motor)
                                    this.motor_selected = option;
                            })

                        if(details.controller_type)
                            this.controller_types.forEach(option => {
                                if(option.attributes.name === details.controller_type)
                                    this.controller_type_selected = option;
                            })

                    })
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                            type: 'error',
                        });
                    });
            } else if (this.$route.query.cart) {
                this.$axios.put(`carts/${this.$route.query.cart}/blinds`,  {token: this.$route.query.token})
                    .then(({data}) => {
                        const details = JSON.parse(data.options)[0];

                        this.width = details.width;
                        this.blind_type_selected = details.blind_type;

                        if(data.note)
                            this.note = data.note;

                        this.finishStyleOptions.forEach(option => {
                            if(option.attributes.name === details.finish_style)
                                this.finish_style_selected = option;
                        })

                        this.finishColourOptions.forEach(option => {
                            if(option.attributes.name === details.finish)
                                this.finish_colour_selected = option;
                        })

                        if(details.custom_colour_code)
                            this.custom_colour_code = details.custom_colour_code;

                        if(details.motor)
                            this.motors.forEach(option => {
                                if(option.attributes.name === details.motor)
                                    this.motor_selected = option;
                            })

                        if(details.controller_type)
                            this.controller_types.forEach(option => {
                                if(option.attributes.name === details.controller_type)
                                    this.controller_type_selected = option;
                            })

                    })
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                            type: 'error',
                        });
                    });

            } else {
                this.$axios.put(`carts/${this.$store.getters.cart}/blinds`,  {token: this.$route.query.token})
                    .then(({data}) => {
                        const details = JSON.parse(data.options)[0];

                        this.width = details.width;
                        this.blind_type_selected = details.blind_type;

                        if(data.note)
                            this.note = data.note;

                        this.finishStyleOptions.forEach(option => {
                            if(option.attributes.name === details.finish_style)
                                this.finish_style_selected = option;
                        })

                        this.finishColourOptions.forEach(option => {
                            if(option.attributes.name === details.finish)
                                this.finish_colour_selected = option;
                        })

                        if(details.custom_colour_code)
                            this.custom_colour_code = details.custom_colour_code;

                        if(details.motor)
                            this.motors.forEach(option => {
                                if(option.attributes.name === details.motor)
                                    this.motor_selected = option;
                            })

                        if(details.controller_type)
                            this.controller_types.forEach(option => {
                                if(option.attributes.name === details.controller_type)
                                    this.controller_type_selected = option;
                            })

                    })
                    .catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                            type: 'error',
                        });
                    });
            }
        }
    },
    async mounted() {
        await this.retrieveBlind();
        await this.retrieveFabrics();
        await this.retrieveArticles();

        if(this.$route.query.token) this.populate();
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    @apply px-8 max-w-6xl mx-auto bg-white flex flex-col;

    & > .header-container {
        @apply flex flex-col sticky top-0 bg-white py-8;
        z-index: 99999;

        @screen lg {
            @apply flex-row
        }

        .title-container {
            @apply flex flex-row items-center;

            a {
                @apply text-primary text-2xl cursor-pointer;

                svg {

                    &:hover {
                        animation: spin 0.2s linear;
                        animation-direction: reverse;

                    }
                }
            }

            h1 {
                @apply font-bold text-center text-2xl ml-6;

                @screen lg {
                    @apply text-3xl max-w-full ml-6;
                }
            }
        }

        .price-container {
            @apply flex flex-row items-center mt-4;

            @screen lg{
                @apply ml-auto mx-0 mt-0;
            }

            .price {
                @apply bg-white border-2 border-grey-light rounded py-2 w-5/12 mr-8;

                @screen lg{
                    @apply w-36;
                }

                p {
                    @apply text-center text-black text-xl font-bold truncate;
                }
            }

            button {
                @apply w-7/12 h-12;
            }
        }
    }

    & > .form-container {
        @apply bg-grey-form border-2 border-grey-form rounded-xl mb-8 p-8;

        @screen lg {
            @apply p-10;
        }

        .form-title {
            @apply text-xl font-bold mb-8;
        }

        h2 {
            @apply text-sm text-black font-bold mb-8;
        }

        .form {
            @apply -mb-4;

            &.main-form {
                @apply mb-2;

                .input-group {
                    .input {
                        width: 50% !important;
                        border-color: theme('colors.primary') !important;
                    }
                }
            }
        }

        .divider {
            @apply mx-auto border-t-2 border-grey-light rounded-md my-8 w-full;

            @screen lg {
                @apply mx-auto;
            }
        }

        .form-wrapper {
            @apply flex flex-row w-full;

            .textboxes-container {
                @apply flex flex-col w-1/4 mr-2;

                .input-group {
                    @apply w-full;
                }
            }

            .note-field {
                @apply w-3/4;
            }
        }

        .custom-colour-field {
            @apply mb-0 mt-8 max-w-sm;
        }
    }

    & > .title {
        @apply font-bold my-4;
    }

    .item-container {
        @apply flex flex-row flex-wrap -m-4;

        .item-card-container {
            @apply w-1/2 p-4;

            @screen md {
                @apply w-1/4;
            }

            @screen lg {
                @apply w-1/5;
            }

            .item-card {
                @apply w-full bg-white rounded-xl flex flex-col h-24 max-w-full px-4 cursor-pointer border-white border-3;
                box-shadow: 0px 10px 15px #00000012;

                &:hover {
                    @apply border-primary;
                }

                &.selected {
                    @apply border-primary;
                }

                .item-card-title {
                    @apply text-center m-auto text-sm font-bold;

                    @screen lg {
                        @apply text-base;
                    }
                }

                .price {
                    @apply text-center m-auto text-sm font-bold;

                    @screen lg{
                        @apply text-base;
                    }
                }
            }
        }
    }
}
</style>