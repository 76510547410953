<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{ original.attributes.name }}</h1>
            </template>
            <template v-slot:right>
                <Button v-if="$store.getters.hasPermission('update users')" className="--primary --small --wider"
                        :class="{spinner: is_saving}" :onclick="save">
                    {{ $t('save') }}
                </Button>
            </template>
        </Headbar>
        <main>
            <Form class="form">
                <SectionHeader :title="$t('blinds.blind_details')"/>
                <div class="form-body">
                    <FormGroupThree>
                        <FormInputText v-model="$v.blind.name.$model" identifier="name" :label="$t('blinds.name')"
                                       :placeholder="$t('blinds.name')" :disabled="is_saving" :has-error="$v.blind.name.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.blind.name.required">
                                    {{ $t('validation.x_is_required', {x: $t('blinds.name')}) }}
                                </p>
                            </template>
                        </FormInputText>

                        <FormInputSelect v-model="$v.blind.type.$model" identifier="role"
                                         :label="$t('blinds.type')" :options="blindTypeOptions"
                                         :placeholder="$t('blinds.type')" :disabled="is_saving"
                                         :has-error="$v.blind.type.$error" track-by="name"
                                         :display-custom-label="(row) => `${row.name}`">
                            <template v-slot:errors>
                                <p v-if="!$v.blind.type.required">
                                    {{ $t('validation.x_is_required', {x: $t('blinds.type')}) }}
                                </p>
                            </template>
                        </FormInputSelect>
                    </FormGroupThree>
                </div>
            </Form>
            <div class="tables-container">
                <div class="table">
                    <SectionHeader :title="$t('blinds.articles')" button="Add" :onclick="toggleAddArticle"/>
                    <vue-good-table
                        mode="remote"
                        styleClass="vgt-table vgt-custom vgt-white"
                        max-height="500px"
                        :columns="article_columns"
                        :rows="articles"
                        :isLoading.sync="is_loading_articles"
                        :search-options="{
                            enabled: false,
                        }"
                        :pagination-options="{
                            enabled: false,
                            mode: 'records'
                        }"
                        :sort-options="{
                          enabled: false,
                        }"
                        :totalRows="totalArticleRecords">
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field === 'attributes'">
                                <p>{{props.row.attributes.name}} - {{props.row.attributes.code}}</p>
                            </div>
                            <div v-else-if="props.column.field === 'after'" class="td-after">
                                <font-awesome-icon :icon="['fal', 'minus-circle']" @click="toggleRemoveArticle(props.row.id)"/>

                                <div class="arrows-container">
                                    <font-awesome-icon :icon="['fal', 'chevron-up']" @click="updateArticleOrder('up', props.row.id)"/>
                                    <font-awesome-icon :icon="['fal', 'chevron-down']" @click="updateArticleOrder('down', props.row.id)"/>
                                </div>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
                <div class="table">
                    <SectionHeader :title="$t('blinds.fabrics')" button="Add" :onclick="toggleAddFabric"/>
                    <vue-good-table
                        mode="remote"
                        styleClass="vgt-table vgt-custom vgt-white"
                        max-height="500px"
                        :columns="fabric_columns"
                        :rows="fabrics"
                        :isLoading.sync="is_loading_fabrics"
                        :search-options="{
                            enabled: false,
                        }"
                        :pagination-options="{
                            enabled: false,
                            mode: 'records'
                         }"
                        :sort-options="{
                          enabled: false,
                        }"
                        :totalRows="totalFabricRecords">
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field === 'attributes'">
                                <p>{{props.row.attributes.name}} - {{props.row.attributes.code}}</p>
                            </div>
                            <div v-else-if="props.column.field === 'after'" class="td-after">
                                <font-awesome-icon :icon="['fal', 'minus-circle']" @click="toggleRemoveFabric(props.row.id)"/>

                                <div class="arrows-container">
                                    <font-awesome-icon :icon="['fal', 'chevron-up']" @click="updateFabricOrder('up', props.row.id)"/>
                                    <font-awesome-icon :icon="['fal', 'chevron-down']" @click="updateFabricOrder('down', props.row.id)"/>
                                </div>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormInputPassword from "../../components/form/FormInputPassword";
import FormGroupThree from "@/components/form/FormGroupThree";
import UsersCreateModal from "@/components/users/UsersCreateModal";
import AssignArticleModal from "@/components/blinds/AssignArticleModal";
import ConfirmModal from "@/components/modal/ConfirmModal";
import AssignFabricModal from "@/components/blinds/AssignFabricModal";

export default {
    name: "blinds-update-page",
    components: {
        FormGroupThree,
        FormGroupTwo, FormInputText, FormInputPassword, FormInputSelect, Button, SectionHeader, Form, Headbar},
    data: function () {
        const article_columns = [
            {
                label: this.$t('blinds.name'),
                field: 'attributes',
                sortable: false,
            },
            {
                field: 'after',
                sortable: false,
            },
        ];

        const fabric_columns = [
            {
                label: this.$t('blinds.name'),
                field: 'attributes',
                sortable: false,
            },
            {
                field: 'after',
                sortable: false,
            },
        ];

        return {
            original: null,
            user: {
                name: null,
                company: null,
                phone: null,
                email: null,
                address: null,
                password: null,
                role: null,
            },
            blind: {
                name: null,
                type: null,
                top_tube_diameter: null
            },
            blindTypeOptions: [],

            articles: [],
            article_columns: article_columns,
            totalArticleRecords: null,
            article_page: 1,

            fabrics: [],
            fabric_columns: fabric_columns,
            totalFabricRecords: null,
            fabric_page: 1,

            is_saving: false,
            is_removing_article: false,
            is_loading_original: false,
            is_loading_blind_types: false,
            is_loading_articles: false,
            is_loading_fabrics: false,
        }
    },
    validations: {
        blind: {
            name: {required},
            type: {required},
            top_tube_diameter: {}
        }
    },
    methods: {
        populate() {
            if (!this.original || !this.blind)
                return;

            this.$v.blind.name.$model = this.original.attributes.name;
            this.$v.blind.top_tube_diameter.$model = this.original.attributes.top_tube_diameter;
            this.$v.blind.type.$model = _.find(this.blindTypeOptions, {column: this.original.attributes.type});
        },
        updateArticleOrder(direction, article_id) {
            let article = this.articles.findIndex(a => a.id === article_id);

            for(let i = 0; i < this.articles.length; i++) {
                this.articles[i].attributes.order = i;
            }

            if(direction === 'up') {
                if(this.articles[article].attributes.order > 0) {
                    this.articles[article].attributes.order--;
                    this.articles[article-1].attributes.order++;
                    this.syncArticles();
                }
            } else if(direction === 'down') {
                if(this.articles[article].attributes.order < this.articles.length - 1) {
                    this.articles[article].attributes.order++;
                    this.articles[article + 1].attributes.order--;
                    this.syncArticles();
                }
            }
        },
        syncArticles() {
            let article_ids = [];

            this.articles.forEach(a => {
                article_ids.push({id: a.id, order: a.attributes.order})
            })

            this.$axios.put(`blinds/${this.$route.params.id}/articles/sync`, {articles: article_ids}).then(({data}) => {
                this.retrieveOriginalBlind();

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;
            });
        },
        updateFabricOrder(direction, fabric_id) {
            let fabric = this.fabrics.findIndex(f => f.id === fabric_id);

            for(let i = 0; i < this.fabrics.length; i++) {
                this.fabrics[i].attributes.order = i;
            }

            if(direction === 'up') {
                if(this.fabrics[fabric].attributes.order > 0) {
                    this.fabrics[fabric].attributes.order--;
                    this.fabrics[fabric-1].attributes.order++;
                    this.syncFabrics();
                }
            } else if(direction === 'down') {
                if(this.fabrics[fabric].attributes.order < this.fabrics.length - 1) {
                    this.fabrics[fabric].attributes.order++;
                    this.fabrics[fabric + 1].attributes.order--;
                    this.syncFabrics();
                }
            }
        },
        syncFabrics() {
            let fabric_ids = [];

            this.fabrics.forEach(a => {
                fabric_ids.push({id: a.id, order: a.attributes.order})
            })

            this.$axios.put(`blinds/${this.$route.params.id}/fabrics/sync`, {fabrics: fabric_ids}).then(({data}) => {
                this.retrieveOriginalBlind();

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;
            });
        },
        async retrieveOriginalUser() {
            this.is_loading_original = false;
            await this.$axios.get(`users/${this.$route.params.id}`)
                .then(({data}) => {
                    this.original = data.data;
                    this.is_loading_original = false;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveOriginalBlind() {
            this.is_loading_original = false;
            await this.$axios.get(`blinds/${this.$route.params.id}`)
                .then(({data}) => {
                    this.original = data.data;

                    this.articles = data.data.relationships.articles.data.sort((a,b) => a.attributes.order - b.attributes.order);
                    this.fabrics = data.data.relationships.fabrics.data.sort((a,b) => a.attributes.order - b.attributes.order);

                    this.populate();

                    this.is_loading_original = false;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('blinds.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveBlindTypes() {
            this.is_loading_blind_types = false;
            await this.$axios.get(`blinds/list-types`)
                .then(({data}) => {
                    this.blindTypeOptions = data;
                    this.is_loading_blind_types = false;
                })
                .catch(e => {
                    this.is_loading_blind_types = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('blinds.error_retrieve_blind_types')),
                        type: 'error',
                    });
                });
        },
        toggleAddArticle() {
            this.$modal.show(
                AssignArticleModal, {},
                {
                    name: 'assign-article-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveOriginalBlind();
                    }
                }
            );
        },
        toggleRemoveArticle(id) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('blinds.remove_article'),
                    message: this.$t('blinds.prompt_remove_article'),
                    confirmText: this.$t('remove'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_removing_article = true;
                            this.$axios.delete(`blinds/${this.$route.params.id}/articles/${id}/detach`)
                                .then(({data}) => {
                                    this.is_removing_article = false;
                                    this.retrieveOriginalBlind();
                                })
                                .catch(e => {
                                    this.is_removing_article = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('blinds.error_remove_article')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        toggleAddFabric() {
            this.$modal.show(
                AssignFabricModal, {},
                {
                    name: 'assign-fabric-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveOriginalBlind();
                    }
                }
            );
        },
        toggleRemoveFabric(id) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('blinds.remove_fabric'),
                    message: this.$t('blinds.prompt_remove_fabric'),
                    confirmText: this.$t('remove'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_removing_fabric = true;
                            this.$axios.delete(`blinds/${this.$route.params.id}/fabrics/${id}/detach`)
                                .then(({data}) => {
                                    this.is_removing_fabric = false;
                                    this.retrieveOriginalBlind();
                                })
                                .catch(e => {
                                    this.is_removing_fabric = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('blinds.error_remove_fabric')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        save() {
            this.$v.blind.$touch();
            if (this.$v.blind.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.blind.$model);
            payload.type = this.blind.type.column;

            this.$axios.patch(`blinds/${this.$route.params.id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('blinds.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.$router.push({name: 'blinds-index'});
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('blinds.error_update')),
                    type: 'error',
                });
            });
        },
    },

    async mounted() {
        await this.retrieveBlindTypes();
        await this.retrieveOriginalBlind();
    },

    head() {
        return {
            title: {
                inner: this.$t('blinds.update_blind')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    main {
        @apply flex flex-col;

        .form {
            @apply mb-8;

            .form-body {
                @apply px-8 py-7 max-w-6xl;
            }
        }

        .tables-container {
            @apply flex flex-col;

            @screen lg {
                @apply flex-row flex-wrap;
            }

            .table {
                @apply mb-8;

                @screen lg {
                    width: calc(50% - 1rem);
                }

                &:nth-child(odd) {
                    @screen lg {
                        @apply mr-4;
                    }
                }

                &:nth-child(even) {
                    @screen lg {
                        @apply ml-4;
                    }
                }

                .td-after {
                    @apply flex;

                    svg {
                        @apply text-negative text-lg cursor-pointer ml-auto my-auto;

                        &:hover {
                            @apply text-primary-over
                        }
                    }

                    .arrows-container {
                        @apply flex flex-col my-auto ml-4;

                        & > svg {
                            @apply text-grey-dark;

                            &.disabled {
                                @apply text-grey;
                            }
                        }
                    }
                }
            }
        }

    }
}
</style>