<template>
    <loading-screen v-if="is_loading_blinds"></loading-screen>
    <div v-else class="page-container">
        <h1>{{ $t('calculators.choose_your_blind') }}</h1>

        <div v-if="blinds.length > 0" class="tiles-wrapper">
            <div class="tiles-container">
                <div class="tile-container" v-if="blinds.some(blind => blind.attributes.type === 'zebra_blind')">
                    <div class="tile">
                        <p class="title">{{ blinds[blinds.findIndex(blind => blind.attributes.type === 'zebra_blind')].attributes.name }}</p>
                        <router-link :to="{name: 'zebra-blind', query: {id: blinds[blinds.findIndex(blind => blind.attributes.type === 'zebra_blind')].id, quote: this.$route.query.quote}}">
                            <font-awesome-icon :icon="['far', 'arrow-circle-right']"/>
                        </router-link>
                    </div>
                </div>

                <div class="tile-container" v-if="blinds.some(blind => blind.attributes.type === 'roller_blind_1' || blind.attributes.type === 'roller_blind_2' || blind.attributes.type === 'roller_blind_3')">
                    <div class="tile">
                        <p class="title">{{ $t('calculators.roller_blind') }}</p>
                        <router-link :to="{name: 'roller-blind-choice', query: {quote: this.$route.query.quote}}">
                            <font-awesome-icon :icon="['far', 'arrow-circle-right']"/>
                        </router-link>
                    </div>
                </div>

                <div class="tile-container" v-if="blinds.some(blind => blind.attributes.type === 'vertical_90mm' || blind.attributes.type === 'vertical_127mm')">
                    <div class="tile">
                        <p class="title">{{ $t('calculators.vertical_blind') }}</p>
                        <router-link :to="{name: 'vertical-blind-slat-choice', query: {quote: this.$route.query.quote}}">
                            <font-awesome-icon :icon="['far', 'arrow-circle-right']"/>
                        </router-link>
                    </div>
                </div>

                <div class="tile-container" v-if="blinds.some(blind => blind.attributes.type === 'curtain_blind')">
                    <div class="tile">
                        <p class="title">{{ blinds[blinds.findIndex(blind => blind.attributes.type === 'curtain_blind')].attributes.name }}</p>
                        <router-link :to="{name: 'sheer-curtain-blind', query: {id: blinds[blinds.findIndex(blind => blind.attributes.type === 'curtain_blind')].id, quote: this.$route.query.quote}}">
                            <font-awesome-icon :icon="['far', 'arrow-circle-right']"/>
                        </router-link>
                    </div>
                </div>

                <div class="tile-container" v-if="blinds.some(blind => blind.attributes.type === 'venetian_blind')">
                    <div class="tile">
                        <p class="title">{{ blinds[blinds.findIndex(blind => blind.attributes.type === 'venetian_blind')].attributes.name }}</p>
                        <router-link :to="{name: 'venetian-blind', query: {id: blinds[blinds.findIndex(blind => blind.attributes.type === 'venetian_blind')].id, quote: this.$route.query.quote}}">
                            <font-awesome-icon :icon="['far', 'arrow-circle-right']"/>
                        </router-link>
                    </div>
                </div>

                <div class="tile-container" v-if="blinds.some(blind => blind.attributes.type === 'pvc_pelmet_cover')">
                    <div class="tile">
                        <p class="title">{{ blinds[blinds.findIndex(blind => blind.attributes.type === 'pvc_pelmet_cover')].attributes.name }}</p>
                        <router-link :to="{name: 'pvc-custom-cover', query: {id: blinds[blinds.findIndex(blind => blind.attributes.type === 'pvc_pelmet_cover')].id, quote: this.$route.query.quote}}">
                            <font-awesome-icon :icon="['far', 'arrow-circle-right']"/>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="subtext">{{$t('calculators.no_calculators_assigned')}}</div>
    </div>
</template>

<script>
import LoadingScreen from "@/components/LoadingScreen";
export default {
    name: "Index",
    components: {LoadingScreen},
    data() {
        return {
            blinds: [],
            is_loading_blinds: false
        }
    },
    methods: {
        retrieveBlinds() {
            this.is_loading_blinds = true;
            this.$axios.get(`users/${this.$store.getters.user.id}/blinds`)
                .then(({data}) => {
                    this.blinds = data.data;
                    this.is_loading_blinds = false;
                })
                .catch(e => {
                    this.is_loading_blinds = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$t('blinds.error_retrieve_blind_types'),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        if(!this.$store.getters.user)
            this.$router.push({name: 'login'});

        this.retrieveBlinds();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.calculators')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    @apply py-8;

    @screen lg {
        @apply py-16;
    }

    h1 {
        @apply font-bold text-center text-2xl mt-4;

        @screen lg {
            @apply text-3xl
        }
    }

    .subtext {
        @apply mx-auto text-center text-lg mt-8 text-grey-dark;
    }

    .tiles-wrapper {
        @apply max-w-4xl mt-8 mx-auto w-full;

        .tiles-container {
            @apply flex flex-row flex-wrap w-full;

            .tile-container {
                @apply w-full py-5;

                @screen lg {
                    @apply w-1/3 p-5;
                }

                .tile {
                    @apply w-full bg-white rounded-xl flex flex-col h-24 mx-auto;
                    box-shadow: 0px 10px 15px #00000012;
                    max-width: 16rem;

                    @screen lg {
                        @apply rounded-xl flex flex-col h-28 max-w-full;
                        box-shadow: 0px 10px 15px #00000012;
                    }

                    .title {
                        @apply text-center text-lg font-bold mb-1.5 my-auto mt-4;

                    }

                    a {
                        @apply text-primary text-2xl mx-auto cursor-pointer my-auto mb-8;

                        svg {
                            &:hover {
                                animation: spin 0.2s linear;
                            }
                        }
                    }

                }
            }


        }

    }

}
</style>