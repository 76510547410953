<template>
    <loading-screen v-if="is_loading_quote && !quote_details"/>
    <div v-else-if="!is_loading_quote && quote_details && quote_details.attributes" class="page-container">
        <div class="page-content-container">
            <div class="header-container">
                <div class="left">
                    <router-link :to="{name: 'quotes'}">
                        <font-awesome-icon :icon="['far', 'arrow-circle-left']"/>
                    </router-link>
                    <h1>{{ $t('cart.quote_details') }}</h1>
                </div>

                <div class="right">
                    <button class="button --primary" @click="confirmChanges">{{ $t('cart.confirm_changes') }}</button>
                </div>
            </div>

            <div class="quote-details-wrapper">
                <div class="quote-details-container">
                    <div class="title-row">
                        <p>{{$t('quotes.quote_id')}}</p>
                        <p>{{$t('quotes.date_issued')}}</p>
                        <p>{{$t('quotes.client')}}</p>
                        <p>{{$t('quotes.contact_number')}}</p>
                        <p>{{$t('quotes.value')}}</p>
                    </div>

                    <div class="quote-row">
                        <div class="details">
                            <p>{{ quote_details.attributes.reference }}</p>
                            <p>{{ quote_details.attributes.confirmed_at | date }}</p>
                            <p>{{ quote_details.attributes.client_details.name }}</p>
                            <p>{{ quote_details.attributes.client_details.phone }}</p>
                            <p>€{{ quote_details.attributes.value }}</p>
                        </div>

                        <div class="icons-container">
                            <font-awesome-icon @click="toggleSwitchToOrder" :icon="['far','file-alt']" :content="$t('quotes.switch_to_order')"
                                               v-tippy="{ placement : 'top',  arrow: true }"/>
                            <font-awesome-icon @click="generateReport" :icon="['fas','print']" :content="$t('quotes.print')" v-tippy="{ placement : 'top',  arrow: true }" :class="{disabled: is_printing}"/>
                            <font-awesome-icon @click="toggleCancelQuote" class="delete-icon" :icon="['far','times-circle']" :content="$t('quotes.cancel_quote')"
                                               v-tippy="{ placement : 'top',  arrow: true }"
                                               :class="{disabled: !acceptedStatuses.find(element => element.includes(quote_details.attributes.status))}"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sales-person-container" v-if="quote_details && quote_details.relationships && quote_details.relationships.user && quote_details.relationships.user.data">
                <h1>{{ $t('cart.sales_person') }}</h1>
                <Form class="form-container">
                    <FormGroupTwo>
                        <FormInputText v-model="quote_details.relationships.user.data.attributes.company"
                                       identifier="company_name" :label="$t('users.company_name')"
                                       :placeholder="$t('users.company_name')" disabled/>
                        <FormInputText v-model="quote_details.relationships.user.data.attributes.name" identifier="name"
                                       :label="$t('users.name')" :placeholder="$t('users.name')" disabled/>
                    </FormGroupTwo>
                    <FormInputText v-model="quote_details.relationships.user.data.attributes.email"
                                   identifier="email_address" :label="$t('users.email_address')"
                                   :placeholder="$t('users.email_address')" disabled/>
                </Form>
            </div>

            <div class="billing-details-container" v-if="quote_details && quote_details.attributes && quote_details.attributes.client_details">
                <h1>{{ $t('cart.client_invoicing_details') }}</h1>
                <Form class="form-container">
                    <div class="form-wrapper">
                        <FormGroupTwo>
                            <FormInputText v-model="invoicing_details.name" identifier="name" :label="$t('users.name')"
                                           :placeholder="$t('users.name')" :class="{error: $v.invoicing_details.name.$error}"
                                            :disabled="!acceptedStatuses.find(element => element.includes(quote_details.attributes.status))"/>
                            <FormInputText v-model="invoicing_details.phone" identifier="phone" :label="$t('users.contact_number')"
                                           :placeholder="$t('users.contact_number')" :class="{error: $v.invoicing_details.phone.$error}"
                                           :disabled="!acceptedStatuses.find(element => element.includes(quote_details.attributes.status))"/>
                        </FormGroupTwo>

                        <FormInputText v-model="invoicing_details.address" identifier="address" :label="$t('users.address')"
                                       :placeholder="$t('users.address')" :class="{error: $v.invoicing_details.address.$error}"
                                       :disabled="!acceptedStatuses.find(element => element.includes(quote_details.attributes.status))"/>
                        <FormInputText v-model="invoicing_details.email" identifier="email_address" :label="$t('users.email_address')"
                                       :placeholder="$t('users.email_address')" :class="{error: $v.invoicing_details.email.$error}"
                                       :disabled="!acceptedStatuses.find(element => element.includes(quote_details.attributes.status))"/>

                        <FormGroupTwo>
                            <FormInputText v-model="discount" identifier="discount" :label="`${$t('cart.discount')} (%)`"
                                           :placeholder="$t('cart.discount')" type="number"
                                           :disabled="!acceptedStatuses.find(element => element.includes(quote_details.attributes.status))"/>
                            <FormInputText v-model="installation" identifier="installation"
                                           :label="`${$t('users.installation')} (€)`"
                                           :placeholder="$t('users.installation')" type="number"
                                           :disabled="!acceptedStatuses.find(element => element.includes(quote_details.attributes.status))"/>
                        </FormGroupTwo>
                    </div>

                    <div class="form-wrapper">
                        <FormGroupTwo>
                            <FormInputText v-model="invoicing_details.company" identifier="company_name"
                                           :label="$t('users.company_name')" :placeholder="$t('users.company_name')"
                                           :disabled="!acceptedStatuses.find(element => element.includes(quote_details.attributes.status))"/>
                            <FormInputText v-model="invoicing_details.vat_num" identifier="vat_number"
                                           :label="$t('users.vat_number')" :placeholder="$t('users.vat_number')"
                                           :disabled="!acceptedStatuses.find(element => element.includes(quote_details.attributes.status))"/>
                        </FormGroupTwo>

                        <FormInputText v-model="note" identifier="note" :label="$t('calculators.note')"
                                       :placeholder="$t('calculators.note')" :useTextarea="true" :largeTextarea="true"
                                       :disabled="!acceptedStatuses.find(element => element.includes(quote_details.attributes.status))"/>

                        <FormGroupTwo>
                            <FormInputText v-model="deposit" identifier="deposit" :label="`${$t('users.deposit')} (€)`"
                                           :placeholder="$t('users.deposit')" type="number"
                                           :disabled="!acceptedStatuses.find(element => element.includes(quote_details.attributes.status))"/>
                        </FormGroupTwo>
                    </div>
                </Form>
            </div>

            <div class="form-container cards"
                 v-if="quote_details && quote_details.relationships && quote_details.relationships.blinds && quote_details.relationships.blinds.data">
                <router-link tag="button" class="button --primary --small add-blind-button" :to="{name: 'calculators-index', query: {'quote': quote_details.id}}"
                             v-if="acceptedStatuses.find(element => element.includes(quote_details.attributes.status))">
                    {{$t('blinds.add_blind')}}
                </router-link>

                <div class="item-container">
                    <div class="item-card-container" v-for="item in quote_details.relationships.blinds.data">
                        <item-card :item="item" :quote_id="quote_details.id" :dont_show_icons="!acceptedStatuses.find(element => element.includes(quote_details.attributes.status))" :dont_show_delete="quote_details.relationships.blinds.data.length === 1"/>
                    </div>
                </div>
            </div>

            <div class="price-breakdown-container">
                <h1>{{ $t('orders.order_cost_summary') }}</h1>

                <div class="details-container">
                    <div class="row">
                        <p class="label">{{ $t('cart.sub_total_1') }}</p>
                        <div class="price">€{{ blinds_total.toFixed(2) }}</div>
                    </div>

                    <div class="row" v-if="quote_details.attributes.discount">
                        <p class="label">{{$t('cart.discount')}} ({{quote_details.attributes.discount}}%)</p>
                        <div class="price">€{{ (blinds_total * (quote_details.attributes.discount / 100)).toFixed(2) }}</div>
                    </div>

                    <div class="row" v-if="quote_details.attributes.installation">
                        <p class="label">{{ $t('cart.installation') }}</p>
                        <div class="price">€{{ quote_details.attributes.installation.toFixed(2) }}</div>
                    </div>

                    <div class="row">
                        <p class="label">{{ $t('cart.sub_total_2') }}</p>
                        <div class="price">€{{(blinds_total - (quote_details.attributes.discount ? (blinds_total * (quote_details.attributes.discount / 100)).toFixed(2) : 0) + quote_details.attributes.installation).toFixed(2)}}</div>
                    </div>

                    <div class="row" v-if="quote_details.attributes.deposit">
                        <p class="label">{{ $t('cart.deposit') }}</p>
                        <div class="price">€{{ quote_details.attributes.deposit.toFixed(2) }}</div>
                    </div>

                    <div class="row top-border">
                        <p class="label">{{ $t('cart.balance_inc_vat') }}</p>
                        <div class="price">€{{quote_details.attributes.value}}</div>
                    </div>
                </div>
            </div>
        </div>
        <vue-html2pdf
            v-if="quote_details"
            :float-layout="true"
            :show-layout="false"
            :enable-download="true"
            :filename="`${quote_details.attributes.reference}`"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="1120px"
            @beforeDownload="beforeDownload($event)"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content" class="pdf-content">
                <div v-for="(array, index) in printing_pages" class="printing-page">
                    <div class="top-row">
                        <h1 class="reference">{{ quote_details.attributes.reference }} - Blinds Qty {{ quote_details.relationships.blinds.data.length }} - Page {{ index + 1 }} / {{ printing_pages.length }} </h1>

                        <div class="sales-person-container" v-if="index === 0 && quote_details && quote_details.relationships && quote_details.relationships.user && quote_details.relationships.user.data">
                            <p><span>SP:</span> {{ quote_details.relationships.user.data.attributes.name }}</p>
                            <p><span>Company:</span> {{ quote_details.relationships.user.data.attributes.company }}</p>
                        </div>
                    </div>

                    <div class="order-details-container" v-if="quote_details && quote_details.relationships && quote_details.relationships.user && quote_details.relationships.user.data && index === 0">
                        <div class="billing-details-container">
                            <div class="card-title-container">
                                <h1 class="card-title">{{$t('cart.client_invoicing_details')}}</h1>
                            </div>

                            <div class="user-details-wrapper">
                                <div class="user-details-container">
                                    <div class="user-detail">
                                        <p class="label">{{ $t('users.date') }}:</p>
                                        <p>{{ $moment.utc(quote_details.attributes.created_at).local().format('DD/MM/YYYY - HH:mm') }}</p>
                                    </div>

                                    <div class="user-detail" v-if="quote_details.attributes.client_details.name">
                                        <p class="label">{{ $t('users.name') }}:</p>
                                        <p>{{ quote_details.attributes.client_details.name }}</p>
                                    </div>

                                    <div class="user-detail" v-if="quote_details.attributes.client_details.phone">
                                        <p class="label">{{ $t('users.contact') }}:</p>
                                        <p>{{ quote_details.attributes.client_details.phone }}</p>
                                    </div>
                                </div>

                                <div class="user-detail" v-if="quote_details.attributes.client_details.address">
                                    <p class="label">{{ $t('users.address') }}:</p>
                                    <p>{{ quote_details.attributes.client_details.address }}</p>
                                </div>

                                <div class="user-detail" v-if="quote_details.attributes.client_details.email">
                                    <p class="label">{{ $t('users.email_address') }}:</p>
                                    <p>{{ quote_details.attributes.client_details.email }}</p>
                                </div>

                                <div class="user-details-container" v-if="quote_details.attributes.client_details.company || quote_details.attributes.client_details.vat_num">
                                    <div class="user-detail" v-if="quote_details.attributes.client_details.company">
                                        <p class="label" >{{ $t('users.company_name') }}:</p>
                                        <p>{{ quote_details.attributes.client_details.company }}</p>
                                    </div>

                                    <div class="user-detail" v-if="quote_details.attributes.client_details.vat_num">
                                        <p class="label" >{{ $t('users.vat_number') }}:</p>
                                        <p>{{ quote_details.attributes.client_details.vat_num }}</p>
                                    </div>
                                </div>

                                <div class="user-detail" v-if="quote_details.attributes.note">
                                    <p class="label">{{ $t('cart.note') }}:</p>
                                    <p>{{ quote_details.attributes.note }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="price-breakdown-container">
                            <div class="card-title-container">
                                <h1 class="card-title">{{$t('cart.order_cost_summary')}}</h1>
                            </div>

                            <div class="details-container">
                                <div class="row">
                                    <p class="label">{{$t('cart.sub_total_1')}}</p>
                                    <div class="price">€{{blinds_total.toFixed(2)}}</div>
                                </div>

                                <div class="row" v-if="quote_details.attributes.discount">
                                    <p class="label">{{$t('cart.discount')}} ({{quote_details.attributes.discount}}%)</p>
                                    <div class="price">€{{(blinds_total * (quote_details.attributes.discount / 100)).toFixed(2)}}</div>
                                </div>

                                <div class="row" v-if="quote_details.attributes.installation">
                                    <p class="label">{{$t('cart.installation')}}</p>
                                    <div class="price">€{{quote_details.attributes.installation.toFixed(2)}}</div>
                                </div>

                                <div class="row">
                                    <p class="label">{{$t('cart.sub_total_2')}}</p>
                                    <div class="price">€{{(blinds_total - (quote_details.attributes.discount ? (blinds_total * (quote_details.attributes.discount / 100)).toFixed(2) : 0) + quote_details.attributes.installation).toFixed(2)}}</div>
                                </div>

                                <div class="row bottom-border" v-if="quote_details.attributes.deposit">
                                    <p class="label">{{$t('cart.deposit')}}</p>
                                    <div class="price">€{{quote_details.attributes.deposit.toFixed(2)}}</div>
                                </div>

                                <div class="row">
                                    <p class="label">{{$t('cart.balance_inc_vat')}}</p>
                                    <div class="price">€{{quote_details.attributes.value}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="item-cards-container">
                        <div class="item-card-container" v-for="item in array">
                            <item-card-printing class="item-card-wrapper" :item="item"/>
                        </div>
                    </div>

                    <div v-if="index !== printing_pages.length-1" class="html2pdf__page-break"/>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import Button from "../../../components/Button";
import ConfirmModal from "@/components/modal/ConfirmModal";
import Form from "@/components/form/Form";
import FormGroupTwo from "@/components/form/FormGroupTwo";
import FormInputText from "@/components/form/FormInputText";
import {required, email} from "vuelidate/lib/validators";
import ItemCard from "@/components/calculators/ItemCard";
import moment from "moment";
import LoadingScreen from "@/components/LoadingScreen";
import _ from "lodash";
import VueHtml2pdf from 'vue-html2pdf';
import ItemCardPrinting from "@/components/calculators/ItemCardPrinting";

export default {
    name: "Checkout",
    components: {LoadingScreen, ItemCard, FormInputText, FormGroupTwo, Form, Button, VueHtml2pdf, ItemCardPrinting},

    data() {
        return {
            acceptedStatuses: ['processing'],
            quote_details: null,
            blinds_total: 0,
            invoicing_details: {
                name: null,
                phone: null,
                address: null,
                email: null,
                company: null,
                vat_num: null,
            },
            installation: null,
            deposit: null,
            note: null,
            discount: null,
            printing_pages: [],
            is_loading_quote: false,
            is_printing: false,
        }
    },
    validations: {
        invoicing_details: {
            name: {required},
            phone: {required},
            address: {required},
            email: {email},
            company: {},
            vat_num: {},
        },
        note: {},
        deposit: {},
        installation: {},
        discount: {},
    },
    computed: {
        quoteTotal() {
            let total = 0;

            this.quote_details.relationships.blinds.data.forEach(blind => {
                total += blind.attributes.value;
            })

            return total;
        }
    },
    methods: {
        confirmChanges() {
            let payload = {
                client_details: {}
            };

            for (const [key, value] of Object.entries(this.invoicing_details)) {
                payload.client_details[key] = value;
            }

            if (this.note)
                payload['note'] = this.note;

            if (this.deposit)
                payload['deposit'] = this.deposit;

            if (this.installation)
                payload['installation'] = this.installation;

            if (this.discount)
                payload['discount'] = this.discount;

            let value = this.blinds_total - (this.discount ? (this.blinds_total * (this.discount / 100)).toFixed(2) : 0) + (this.installation ? parseInt(this.installation) : 0) - (this.deposit ? parseInt(this.deposit) : 0).toFixed(2);

           if(value < 0) {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(this.$t('quotes.error_value_negative')),
                    type: 'error',
                });

                return;
            }

            this.$axios.patch(`quotes/${this.$route.params.id}`, {...payload}).then(({data}) => {
                this.getQuote();
            }).catch(e => {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('cart.error_update_quote')),
                    type: 'error',
                });
            });
        },
        toggleSwitchToOrder() {
            this.$v.invoicing_details.$touch();
            if(this.$v.invoicing_details.$anyError) return;

            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('quotes.switch_to_order'),
                    message: this.$t('quotes.are_you_sure_switch_to_order'),
                    confirmText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            let payload = {
                                status: 'processing',
                                value: this.quote_details.attributes.value,
                                client_details: this.invoicing_details
                            };

                            if(this.note)
                                payload = {...payload, note: this.note};

                            if(this.installation)
                                payload = {...payload, installation: this.installation};

                            if(this.deposit)
                                payload = {...payload, deposit: this.deposit};

                            if(this.discount)
                                payload = {...payload, discount: this.discount};

                            this.$axios.post(`quotes/${this.quote_details.id}/place-order`, payload)
                                .then(({data}) => {
                                    this.$notify({
                                        text: this.$t('quotes.success_switch_to_order'),
                                        type: 'success',
                                    });

                                    this.$router.push({name: 'my-orders'});
                                    this.close(true);
                                }).catch(e => {
                                this.$notify({
                                    title: this.$t('error'),
                                    text: this.$larerror(e.response.data.errors, this.$t('quotes.error_switch_to_order')),
                                    type: 'error',
                                });
                            });
                        }
                    }
                }
            );
        },
        toggleCancelQuote() {
            if (this.quote_details.attributes.status === 'cancelled') return;

            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('quotes.cancel_quote'),
                    message: this.$t('quotes.are_you_sure_cancel_quote'),
                    confirmText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.$axios.patch(`quotes/${this.$route.params.id}`, {status: 'cancelled'}).then(({data}) => {
                                this.getQuote();
                            }).catch(e => {
                                this.$notify({
                                    title: this.$t('error'),
                                    text: this.$larerror(e.response.data.errors, this.$t('quotes.error_cancel_quote')),
                                    type: 'error',
                                });
                            });
                        }
                    }
                }
            );
        },
        async updateQuoteValue() {
            await this.$axios.patch(`quotes/${this.$route.params.id}`, {value: (this.blinds_total - (this.quote_details.attributes.discount ? (this.blinds_total * (this.quote_details.attributes.discount / 100)).toFixed(2) : 0) + (this.quote_details.attributes.installation ? parseInt(this.quote_details.attributes.installation) : 0) - (this.quote_details.attributes.deposit ? parseInt(this.quote_details.attributes.deposit) : 0)).toFixed(2)})
                .then(({data}) => {
                    this.quote_details.attributes.value = data.data.attributes.value;
                })
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_quote')),
                        type: 'error',
                    });
                });
        },
        async getQuote() {
            this.is_loading_quote = true;
            this.blinds_total = 0;

            this.$axios.get(`quotes/${this.$route.params.id}`)
                .then(async ({data}) => {
                    this.quote_details = data.data;


                    this.quote_details.relationships.blinds.data.sort((a, b) => a.attributes.order - b.attributes.order);

                    if (this.quote_details.relationships.blinds.data.length) {
                        this.quote_details.relationships.blinds.data.forEach(blind => {
                            blind.attributes.options = JSON.parse(blind.attributes.options);
                            this.blinds_total += blind.attributes.value;
                        })
                    }

                    this.printing_pages = [];
                    let temp_blinds_printing_array = _.clone(this.quote_details.relationships.blinds.data);

                    for(let i = 0; i < this.quote_details.relationships.blinds.data.length / 3; i++) {
                        if(i === 0 && temp_blinds_printing_array.slice(0, 3).find(blind => blind.attributes.type === 'curtain_blind')) {
                            this.printing_pages.push([]);
                        }

                        this.printing_pages.push(temp_blinds_printing_array.splice(0, 3));
                    }

                    if (this.quote_details && this.quote_details.attributes && this.quote_details.attributes.client_details) {
                        this.invoicing_details.name = this.quote_details.attributes.client_details?.name;
                        this.invoicing_details.phone = this.quote_details.attributes.client_details?.phone;
                        this.invoicing_details.address = this.quote_details.attributes.client_details?.address;
                        this.invoicing_details.email = this.quote_details.attributes.client_details?.email;
                        this.invoicing_details.company = this.quote_details.attributes.client_details?.company;
                        this.invoicing_details.vat_num = this.quote_details.attributes.client_details?.vat_num;
                        this.invoicing_details.city = this.quote_details.attributes.client_details?.city;
                        this.invoicing_details.post_code = this.quote_details.attributes.client_details?.post_code;
                    }

                    if (this.quote_details.attributes.note)
                        this.note = this.quote_details.attributes.note;

                    if (this.quote_details.attributes.deposit)
                        this.deposit = this.quote_details.attributes.deposit;

                    if (this.quote_details.attributes.installation)
                        this.installation = this.quote_details.attributes.installation;

                    if (this.quote_details.attributes.discount)
                        this.discount = this.quote_details.attributes.discount;

                    await this.updateQuoteValue();

                    this.is_loading_quote = false;
                })
                .catch(e => {
                    this.is_loading_quote = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_quote')),
                        type: 'error',
                    });
                });
        },
        async generateReport() {
            this.is_printing = true;
            await this.$refs.html2Pdf.generatePdf()
        },
        async beforeDownload() {
            this.is_printing = true;
        },
        async hasDownloaded(){
            this.is_printing = false;
        },
    },
    filters: {
        date(value) {
            return moment(value).format('DD-MM-YYYY');
        },
        dateTime(value) {
            return moment(value).format('DD-MM-YYYY hh:mm:ss A');
        }
    },
    async mounted() {
        await this.getQuote();
    }
}
</script>

<style lang="scss" scoped>
@media print {
    @page {
        size: landscape
    }
}

.pdf-content {
    @apply px-8 py-2;

    .printing-page {
        @apply pt-4;

        &:first-of-type {
            @apply pt-0;
        }
    }

    .top-row {
        @apply flex flex-row justify-between mb-4;

        .reference {
            @apply text-black font-bold text-2xl my-auto -mt-1;
        }

        .sales-person-container {
            @apply flex flex-row border-1 border-black rounded-md py-4 px-2 bg-grey-light mt-1;

            p {
                @apply text-sm px-2 -mt-3;

                &.title {
                    @apply font-bold;
                }

                span {
                    @apply font-bold;
                }
            }
        }
    }

    .item-cards-container {
        @apply flex flex-row -mx-2;

        .item-card-container {
            @apply w-1/3 px-2 mt-2;
        }
    }

    .order-details-container {
        @apply flex flex-row mb-2;

        .billing-details-container {
            @apply flex flex-col rounded-md border-1 border-black bg-grey-light mr-2;
            width: 60%;

            .card-title-container {
                @apply px-4 py-1 bg-black text-white;

                .card-title {
                    @apply font-bold text-sm -mt-2 mb-2;
                }
            }

            .user-details-wrapper {
                @apply px-4 py-2;

                .user-details-container {
                    @apply flex flex-row;
                }

                .user-detail {
                    @apply flex flex-row mr-8;

                    p {
                        &:nth-child(even) {
                            @apply ml-1;
                        }
                    }

                    &:last-child {
                        @apply mr-0;
                    }

                    .label {
                        @apply font-bold mb-2 text-xs;
                    }

                    p {
                        @apply mb-2 text-xs;
                    }
                }
            }

            & > h1 {
                @apply font-bold text-lg mb-4;
            }
        }

        .price-breakdown-container {
            @apply ml-2 rounded-md border-1 border-black bg-grey-light;
            width: 40%;

            .card-title-container {
                @apply px-4 py-1 bg-black text-white;

                .card-title {
                    @apply font-bold text-sm -mt-2 mb-2;
                }
            }

            .details-container {
                @apply flex flex-col flex-wrap max-h-40 px-4 py-2 -mx-4;

                .row {
                    @apply flex flex-row text-xs justify-between mx-4 mb-4;

                    .label {
                        @apply font-bold mr-4;
                    }

                    &.bottom-border {
                        @apply pb-4 mb-0 border-b border-black;
                    }
                }
            }
        }
    }
}

.page-container {
    @apply w-full h-full mx-auto bg-white px-8;

    & > .page-content-container {
        @apply flex flex-col max-w-6xl mx-auto w-full;

        & > .header-container {
            @apply flex flex-row justify-between py-8;

            & > .left {
                @apply flex flex-row;

                & > a {
                    @apply my-auto mr-4;

                    & > svg {
                        @apply text-primary text-2xl cursor-pointer;

                        &:hover {
                            @apply text-primary-over;
                        }
                    }
                }

                & > h1 {
                    @apply font-bold text-2xl my-auto;
                }
            }

            & > .right {
                @apply flex flex-row;

                & > .price-container {
                    @apply border-2 border-grey-light rounded p-2 px-6 mr-6 text-black font-bold text-2xl;
                }

            }
        }

        & > .quote-details-wrapper {
            @apply overflow-x-scroll mb-10;

            @screen lg {
                @apply overflow-x-auto;
            }

            & > .quote-details-container {
                @apply flex flex-col mt-12 w-260;

                @screen lg {
                    @apply w-full;
                }

                & > .title-row {
                    @apply flex flex-row w-200 mb-4 pl-12;

                    @screen lg {
                        @apply w-4/5;
                    }

                    & > p {
                        @apply w-1/5 font-bold text-left text-lg;
                    }
                }

                & > .quote-row {
                    @apply flex flex-row w-full mb-4 bg-grey-form rounded-xl py-6;

                    &:last-of-type {
                        @apply mb-0;
                    }

                    & > .details {
                        @apply flex flex-row w-200 pl-12;

                        @screen lg {
                            @apply w-4/5;
                        }

                        & > p {
                            @apply w-1/5 font-bold text-left text-lg text-grey-dark capitalize;
                        }
                    }

                    & > .icons-container {
                        @apply flex flex-row w-auto ml-auto;

                        & > svg {
                            @apply text-2xl text-grey-dark cursor-pointer ml-4;

                            &:hover {
                                @apply text-grey-darker;
                            }
                        }

                        & > .delete-icon {
                            @apply text-primary mx-4 mr-8;

                            &:hover {
                                @apply text-primary-over;
                            }

                            &.disabled {
                                @apply text-primary-dimmed cursor-not-allowed;
                            }
                        }
                    }
                }
            }
        }

        & > .price-breakdown-container {
            @apply flex flex-col rounded-xl bg-grey-form p-8 pb-6 mb-10;

            & > h1 {
                @apply font-bold text-xl mb-6;
            }

            .details-container {
                @apply flex flex-col px-4 py-2;

                .row {
                    @apply flex flex-row text-sm mb-4 w-56 justify-between;

                    .label {
                        @apply font-bold;
                    }

                    &.top-border {
                        @apply border-t-2 border-primary pt-3;
                    }
                }
            }
        }

        & > .sales-person-container {
            @apply flex flex-col rounded-xl bg-grey-form p-8 pb-6 mb-10;

            & > h1 {
                @apply font-bold text-xl mb-6;
            }

            & > .form-container {
                @apply flex flex-col bg-grey-form;

                @screen lg {
                    @apply flex flex-row;
                }

                & > .input-group-two {
                    @screen lg {
                        @apply w-1/2 mr-1;
                    }
                }

                & > .input-group {
                    @screen lg {
                        @apply w-1/2 ml-1;
                    }
                }

                .form-wrapper {
                    @screen lg {
                        @apply w-1/2 mx-2;
                    }
                }
            }
        }

        & > .billing-details-container {
            @apply flex flex-col rounded-xl bg-grey-form p-8 pb-6 mb-10;

            & > h1 {
                @apply font-bold text-xl mb-6;
            }

            & > .form-container {
                @apply flex flex-col -mx-2 bg-grey-form;

                @screen lg {
                    @apply flex flex-row;
                }

                .form-wrapper {
                    @apply w-full;

                    @screen lg {
                        @apply w-1/2 mx-2;
                    }
                }
            }
        }

        & > .form-container {
            @apply bg-grey-form border-2 border-grey-form rounded-xl py-8 mb-8;

            & > .item-container {
                @apply flex flex-row flex-wrap w-full px-3;

                .item-card-container {
                    @apply w-full py-5 px-4;

                    @screen md {
                        @apply w-1/2 p-3;
                    }
                }
            }

            &.cards {
                @apply flex flex-col;

                & > .add-blind-button {
                    @apply ml-auto mr-6 mb-4;

                    & > svg {
                        @apply text-3xl text-primary;
                    }
                }
            }
        }
    }

}
</style>